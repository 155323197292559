

export default function Button({ children, clickFunction, }) {
    return (
        <button onClick={clickFunction}
            className={`inline-block text-[#372039] font-[600] my-2 px-4 md:px-4 py-1 w-full
                    shadow-[0px_4px_3px_rgba(0,0,0,0.3)] uppercase
                    rounded-[25px] font-pressio-condensed
                    bg-gradient-to-b from-[#F8C959] via-[#FEF060] via-[#FBD648] to-[#B4742D]
                    hover:from-[#9FCFFF] hover:via-[#D4EBFF] hover:via-[#A6D4FF] hover:to-[#5384A2]
                    `}
        >
            {children}
        </button>
    )
}