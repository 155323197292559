import { useEffect, useRef, useState } from "react";
import styles from "./FrameCanvas.module.css";

import * as AssetAPI from "../utils/assetApi";
import Button from "../components/Button";

import JSZip from "jszip";
import FileSaver from "file-saver";
import JSZipUtils from "jszip-utils";

import { tokenData } from "../utils/data";

const SnugDealers_white = require("../images/drugWar/Builder/Frames/Snug Dealers/SnugDealers_white.png");
const SnugDealers_gray = require("../images/drugWar/Builder/Frames/Snug Dealers/SnugDealers_gray.png");
const SetTrippers_white = require("../images/drugWar/Builder/Frames/Set Trippers/SetTrippers_white.png");
const SetTrippers_gray = require("../images/drugWar/Builder/Frames/Set Trippers/SetTrippers_gray.png");
const SetTrippers_blue = require("../images/drugWar/Builder/Frames/Set Trippers/SetTrippers_blue.png");
const BigPharma_white = require("../images/drugWar/Builder/Frames/Big Pharma/BigPharma_white.png");
const BigPharma_gray = require("../images/drugWar/Builder/Frames/Big Pharma/BigPharma_gray.png");
const BigPharma_blue = require("../images/drugWar/Builder/Frames/Big Pharma/BigPharma_blue.png");
const BigPharma_green = require("../images/drugWar/Builder/Frames/Big Pharma/BigPharma_green.png");
const Kingpin_white = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_white.png");
const Kingpin_gray = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_gray.png");
const Kingpin_blue = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_blue.png");
const Kingpin_green = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_green.png");
const Kingpin_gold = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_gold.png");
const star_img = require("../images/drugWar/Star.png");


let timeoutId = null;
let startProductBarPos = -1;
let nftokens = ["", "", "", ""];

let tempTeamName = "";

export default function FrameCanvas({ nfts, myteamName, frameUrl, teamMember, downloaded, downloadFront }) {
    const canvasRef = useRef(null);
    const downloadButtonRef = useRef(null);
    const [teamName, setTeamName] = useState("MY TEAM NAME");
    // const [teamMemberStr, setTeamMemberStr] = useState("");
    const [frameImg, setFrameImg] = useState("");

    const getImageUrl = async (tokenId) => {
        // return AssetAPI.getImageUrl(tokenId);
        const addRandomToUrl = true;
        let url = AssetAPI.getImageUrl(tokenId, 0, addRandomToUrl);
        let loc = document.location.origin;
        // return url;
        try {
            let response = await fetch(url, {
                method: "GET",
                headers: new Headers({
                    Origin: loc,
                }),
                mode: "cors",
            });
            let blob = response.blob();
            return blob;
        } catch (e) {
            console.error(e);
        }
    };

    const drawName = (name) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(function () {
            addLastLayer(function () {
                const canvas = canvasRef.current;
                const context = canvas.getContext("2d");
                context.font = "6em octin-stencil";
                if (frameUrl.slice(-5) == 'white') {
                    context.fillStyle = "#414042";
                } else {
                    context.fillStyle = "#FFFFFF";
                }
                context.textAlign = 'center';
                context.fillText(myteamName, (canvas.width) / 2, 1740);
                if (frameUrl.slice(-5) == 'white') {
                    context.fillStyle = "#939598";
                } else if (frameUrl.slice(-5) == 'green') {
                    context.fillStyle = "#2D3F1C";
                } else if (frameUrl.slice(-4) == 'gray') {
                    context.fillStyle = "#323133";
                } else if (frameUrl.slice(-4) == 'blue') {
                    context.fillStyle = "#072947";
                } else if (frameUrl.slice(-4) == 'gold') {
                    context.fillStyle = "#4B3515";
                } else {
                    context.fillStyle = "#939598";
                }

                //Team Members
                context.font = "normal 3.5em pressio-condensed";
                context.fillText(teamMember.toString() == "" ? tempTeamName : teamMember.toString(), (canvas.width) / 2, 1810);
            });
        }, 10);
    };

    const addLastLayer = (callback) => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        const image = new Image();
        image.addEventListener("load", function () {
            context.drawImage(this, 0, 0);
            if (callback) {
                callback();
            }
        });
        if (frameUrl == "SnugDealers_white") image.src = SnugDealers_white;
        else if (frameUrl == "SnugDealers_gray") image.src = SnugDealers_gray;
        else if (frameUrl == "SetTrippers_white") image.src = SetTrippers_white;
        else if (frameUrl == "SetTrippers_gray") image.src = SetTrippers_gray;
        else if (frameUrl == "SetTrippers_blue") image.src = SetTrippers_blue;
        else if (frameUrl == "BigPharma_white") image.src = BigPharma_white;
        else if (frameUrl == "BigPharma_gray") image.src = BigPharma_gray;
        else if (frameUrl == "BigPharma_blue") image.src = BigPharma_blue;
        else if (frameUrl == "BigPharma_green") image.src = BigPharma_green;
        else if (frameUrl == "Kingpin_white") image.src = Kingpin_white;
        else if (frameUrl == "Kingpin_gray") image.src = Kingpin_gray;
        else if (frameUrl == "Kingpin_blue") image.src = Kingpin_blue;
        else if (frameUrl == "Kingpin_green") image.src = Kingpin_green;
        else if (frameUrl == "Kingpin_gold") image.src = Kingpin_gold;
        else image.src = SnugDealers_white;

        // if(frameUrl=="../images/drugWar/Builder/Frames/Kingpin/Kingpin_white.png"){
        //     image.src = SnugDealers_white_img;
        // }else{
        //     image.src = SnugDealers_gray_img;
        // }
    };
    useEffect(() => {
        setFrameImg(frameUrl);
        drawName(myteamName);
    }, [frameUrl]);

    useEffect(() => {
        if (downloaded >= 1) {
            const canvas = canvasRef.current;
            downloadFront(canvas.toDataURL());
            // const button = document.createElement('button');
            // button.setAttribute("download", teamName + ".png");
            // button.setAttribute("href", canvas.toDataURL());
            // button.click();
            // const link = document.createElement('a');
            // link.download = "drugreceipts.png";
            // link.href = canvas.toDataURL();
            // link.click();

            // let links = [];

            // links.push(canvas.toDataURL());
            // var zip = new JSZip();
            // var count = 0;
            // var zipFilename = "DRx_#_png.zip";
            // links.forEach(function (url, i) {
            //     var filename = "drug_receipts_front.png";//links[i].substring(links[i].lastIndexOf("/") + 1);
            //     JSZipUtils.getBinaryContent(url, function (err, data) {
            //         if (err) {
            //             throw err;
            //         }
            //         zip.file(filename, data, { binary: true });
            //         count++;
            //         if (count === links.length) {
            //             zip.generateAsync({ type: "blob" }).then(function (content) {
            //                 FileSaver.saveAs(content, zipFilename);
            //             });
            //         }
            //     });
            // });
        }
    }, [downloaded]);

    useEffect(() => {
        setTeamName(myteamName);
        drawName(myteamName);
    }, [myteamName]);

    useEffect(async () => {
        nftokens = nfts;
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        tempTeamName = checkTeam(nftokens);
        let emptyCount = 0;
        for (let i = 0; i < nftokens.length; i++) {
            let x = 100;
            let y = 100;
            switch (i) {
                case 1:
                    x = 798;
                    break;
                case 2:
                    y = 800;
                    break;
                case 3:
                    x = 798;
                    y = 800;
                    break;
            }

            if (nfts[i] === "") {
                emptyCount++;
            }
            await drawNftImage1(context, i, x, y);
            // Promise.all([drawNftImage(context, 0), drawNftImage(context, 1), drawNftImage(context, 2), drawNftImage(context, 3)]);
            // setTimeout(async () => {
            //     if (nfts[i] === "") {
            //         context.clearRect(x, y, 676, 676);
            //         drawName(teamName);
            //     } else {
            //         const image = new Image();
            //         image.crossOrigin = "anonymous";
            //         image.addEventListener("load", function () {
            //             context.drawImage(this, x, y, 676, 676);
            //             // let teamMember = checkTeam();
            //             // setTeamMemberStr(teamMember);
            //             drawName(teamName);
            //         });
            //         const blob = await getImageUrl(nfts[i]);
            //         image.src = URL.createObjectURL(blob);
            //     }
            // }, 750);
        }
    }, [nfts]);

    const checkTeam = (current) => {
        let index = current.indexOf("");
        if (index < 0) {
            let Num = 0;
            // let rltStr="";
            for (let i = 0; i < current.length; i++) {
                if (tokenData[current[i]].Character.toString() == "Tabbi") {
                    Num += 1000;
                } else if (tokenData[current[i]].Character.toString() == "Cap") {
                    Num += 100;
                } else if (tokenData[current[i]].Character.toString() == "Rex") {
                    Num += 10;
                } else if (tokenData[current[i]].Character.toString() == "Syrin") {
                    Num += 1;
                }
                // if(i==0){
                //     rltStr=rltStr+tokenData[current[i]].Character.toString();
                // }else{
                //     rltStr=rltStr+" "+tokenData[current[i]].Character.toString();
                // }
            }
            if (Num == 4000) {
                return "TEAM TABBI";
            } else if (Num == 400) {
                return "CAP CREW";
            } else if (Num == 40) {
                return "REX RIDERS";
            } else if (Num == 4) {
                return "SYRIN SQUAD";
            } else if (Num == 1111) {
                return "DRx SET";
            } else {
                return "Drug Receipts";
            }
        } else {
            return "";
        }
    }

    const drawNftImage1 = async (context, i, x, y) => {
        if (nftokens[i] === "") {
            context.clearRect(x, y, 676, 676);
            drawName(teamName);
        } else {
            const image = new Image();
            image.crossOrigin = "anonymous";

            const blob = await getImageUrl(nftokens[i]);
            image.src = URL.createObjectURL(blob);

            if (nftokens[i] === "") {
                context.clearRect(x, y, 676, 676);
                drawName(teamName);
            } else {
                image.addEventListener("load", function () {
                    context.drawImage(this, x, y, 676, 676);
                    drawName(teamName);
                });
            }
        }
    }

    // const drawNftImage = async (context, i) => {
    //     return new Promise(async (context, i) => {
    //         if (nfts[i] === "") {
    //             context.clearRect(x, y, 676, 676);
    //             drawName(teamName);
    //         } else {
    //             const image = new Image();
    //             image.crossOrigin = "anonymous";
    //             image.addEventListener("load", function () {
    //                 context.drawImage(this, x, y, 676, 676);
    //                 // let teamMember = checkTeam();
    //                 // setTeamMemberStr(teamMember);
    //                 drawName(teamName);
    //             });
    //             const blob = await getImageUrl(nfts[i]);
    //             console.log("Promise", nfts[i]);
    //             image.src = URL.createObjectURL(blob);
    //         }
    //     })
    // }

    // const downloadImage = () => {
    //     const canvas = canvasRef.current;
    //     const button = downloadButtonRef.current;
    //     button.setAttribute("download", teamName + ".png");
    //     button.setAttribute("href", canvas.toDataURL());
    // };

    const containerRef = useRef(null);
    useEffect(() => {
        function findPosY(obj) {
            var curtop = 0;
            if (typeof obj.offsetParent != "undefined" && obj.offsetParent) {
                while (obj.offsetParent) {
                    curtop += obj.offsetTop;
                    obj = obj.offsetParent;
                }
                curtop += obj.offsetTop;
            } else if (obj.y) curtop += obj.y;
            return curtop;
        }

        const c = containerRef.current;
        const ctr = c.parentNode.parentNode.parentNode;
        ctr.onscroll = () => {
            var w =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
            if (w < 640) {
                return;
            }

            var bar = containerRef.current;
            if (startProductBarPos < 0) startProductBarPos = findPosY(bar);

            if (ctr.scrollTop > startProductBarPos) {
                bar.style.position = "fixed";
                bar.style.top = 0;
            } else {
                bar.style.position = "relative";
            }
        };
    }, []);

    return (
        <div ref={containerRef} className="">
            <div className="">
                <canvas
                    ref={canvasRef}
                    width="1576"
                    height="1895"
                    className={styles.canvas}
                />
            </div>
            {/* <div className="opacity-[0%]">
                <Button clickFunction={downloadImage}>
                    <a
                        className={styles.downloadButton}
                        target="_blank"
                        ref={downloadButtonRef}
                        rel="noreferrer"
                    >
                        DOWNLOAD PNG
                    </a>
                </Button>
            </div> */}
        </div>
    );
}
