export const tokenData = [
    {
        "ID": 0,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 10,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 11,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 12,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 13,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 14,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 15,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 16,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 17,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 18,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 19,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 20,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 21,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 22,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 23,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 24,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 25,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 26,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 27,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 28,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 29,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 30,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 31,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 32,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 33,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 34,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 35,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 36,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 37,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 38,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 39,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 40,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 41,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 42,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 43,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 44,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 45,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 46,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 47,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 48,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 49,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 50,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 51,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 52,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 53,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 54,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 55,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 56,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 57,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 58,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 59,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 60,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 61,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 62,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 63,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 64,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 65,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 66,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 67,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 68,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 69,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 70,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 71,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 72,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 73,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 74,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 75,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 76,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 77,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 78,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 79,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 80,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 81,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 82,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 83,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 84,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 85,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 86,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 87,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 88,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 89,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 90,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 91,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 92,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 93,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 94,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 95,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 96,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 97,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 98,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 99,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 100,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 101,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 102,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 103,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 104,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 105,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 106,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 107,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 108,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 109,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 110,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 111,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 112,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 113,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 114,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 115,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 116,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 117,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 118,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 119,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 120,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 121,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 122,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 123,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 124,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 125,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 126,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 127,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 128,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 129,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 130,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 131,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 132,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 133,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 134,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 135,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 136,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 137,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 138,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 139,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 140,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 141,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 142,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 143,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 144,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 145,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 146,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 147,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 148,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 149,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 150,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 151,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 152,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 153,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 154,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 155,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 156,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 157,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 158,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 159,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 160,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 161,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 162,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 163,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 164,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 165,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 166,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 167,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 168,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 169,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 170,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 171,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 172,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 173,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 174,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 175,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 176,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 177,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 178,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 179,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 180,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 181,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 182,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 183,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 184,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 185,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 186,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 187,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 188,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 189,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 190,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Pink TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 191,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "Wings",
        "Background": "Light Gray"
    },
    {
        "ID": 192,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 193,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 194,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 195,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 196,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 197,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 198,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 199,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 200,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 201,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 202,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 203,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 204,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 205,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 206,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 207,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 208,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 209,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 210,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 211,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 212,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 213,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 214,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 215,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 216,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 217,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 218,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 219,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 220,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 221,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 222,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 223,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 224,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 225,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 226,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 227,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 228,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 229,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 230,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 231,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 232,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 233,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 234,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 235,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 236,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 237,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 238,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 239,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 240,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 241,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 242,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 243,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 244,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 245,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 246,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 247,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 248,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 249,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 250,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 251,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 252,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 253,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 254,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 255,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 256,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 257,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 258,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 259,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 260,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 261,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Purple"
    },
    {
        "ID": 262,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 263,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 264,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 265,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 266,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 267,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 268,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 269,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 270,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 271,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 272,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 273,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 274,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 275,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 276,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 277,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 278,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 279,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 280,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 281,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 282,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 283,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 284,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 285,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 286,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 287,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 288,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 289,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 290,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 291,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 292,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 293,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 294,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 295,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 296,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 297,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 298,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 299,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 300,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 301,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Pitchfork",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 302,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red"
    },
    {
        "ID": 303,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 304,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 305,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 306,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 307,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 308,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 309,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 310,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Blue"
    },
    {
        "ID": 311,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 312,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 313,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 314,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 315,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 316,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 317,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 318,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 319,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 320,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 321,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 322,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 323,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 324,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 325,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 326,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 327,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 328,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 329,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 330,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 331,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 332,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 333,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 334,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 335,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 336,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 337,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 338,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 339,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 340,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 341,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 342,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 343,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 344,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 345,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 346,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 347,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 348,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 349,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 350,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 351,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 352,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 353,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 354,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 355,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 356,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 357,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 358,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 359,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 360,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bandage",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 361,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 362,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 363,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 364,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 365,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 366,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 367,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 368,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 369,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 370,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 371,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 372,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 373,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 374,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 375,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 376,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 377,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 378,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 379,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 380,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 381,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 382,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 383,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 384,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 385,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 386,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 387,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 388,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 389,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 390,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 391,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 392,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 393,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 394,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 395,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 396,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 397,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 398,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 399,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 400,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 401,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 402,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 403,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 404,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 405,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 406,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 407,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 408,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 409,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 410,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 411,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 412,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 413,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 414,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 415,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 416,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 417,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 418,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 419,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 420,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 421,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 422,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 423,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 424,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 425,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 426,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 427,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 428,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 429,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 430,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 431,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 432,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 433,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 434,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 435,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "Devil Tail",
        "Background": "Pink"
    },
    {
        "ID": 436,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 437,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 438,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 439,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Light Gray"
    },
    {
        "ID": 440,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 441,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 442,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 443,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 444,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 445,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 446,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 447,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 448,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 449,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 450,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 451,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 452,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 453,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 454,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 455,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 456,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 457,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 458,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Purple"
    },
    {
        "ID": 459,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 460,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 461,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 462,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 463,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 464,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 465,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 466,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 467,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 468,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 469,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 470,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 471,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 472,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 473,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 474,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 475,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 476,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 477,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 478,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 479,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 480,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 481,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 482,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 483,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 484,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 485,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 486,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 487,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 488,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 489,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 490,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 491,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 492,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 493,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 494,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 495,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 496,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 497,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 498,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 499,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 500,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 501,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 502,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 503,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 504,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 505,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 506,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 507,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 508,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 509,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 510,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 511,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 512,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 513,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 514,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 515,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 516,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 517,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 518,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 519,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 520,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 521,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 522,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 523,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 524,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 525,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 526,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 527,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 528,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 529,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 530,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 531,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 532,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 533,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 534,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 535,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 536,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 537,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 538,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 539,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 540,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 541,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 542,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 543,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 544,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 545,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 546,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 547,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 548,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 549,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 550,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 551,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Light Gray"
    },
    {
        "ID": 552,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 553,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 554,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 555,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 556,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 557,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 558,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 559,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 560,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 561,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 562,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 563,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 564,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 565,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 566,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 567,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 568,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 569,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 570,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 571,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 572,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 573,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 574,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 575,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 576,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 577,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 578,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 579,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 580,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 581,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 582,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 583,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 584,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 585,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 586,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 587,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 588,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 589,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 590,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 591,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 592,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 593,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 594,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 595,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 596,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 597,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 598,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 599,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 600,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 601,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 602,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 603,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 604,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 605,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 606,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 607,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 608,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 609,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 610,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 611,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 612,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 613,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 614,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 615,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 616,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 617,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 618,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 619,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 620,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 621,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 622,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 623,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 624,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 625,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 626,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 627,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 628,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 629,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 630,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 631,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 632,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 633,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 634,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 635,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 636,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 637,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 638,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 639,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 640,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 641,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 642,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 643,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 644,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 645,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 646,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 647,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 648,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red Wine"
    },
    {
        "ID": 649,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 650,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 651,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 652,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 653,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 654,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 655,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 656,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 657,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 658,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 659,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 660,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Yellow"
    },
    {
        "ID": 661,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 662,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 663,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 664,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 665,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 666,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 667,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 668,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 669,
        "Character": "Cap",
        "Body Color": "Murdered Out",
        "Speech Bubble": "",
        "Right Arm": "Murdered Out Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Murdered Out Knife",
        "Right Leg": "Murdered Out Skate",
        "Left Leg": "Murdered Out Crossed Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue Special"
    },
    {
        "ID": 670,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 671,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 672,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 673,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 674,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 675,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 676,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 677,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 678,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 679,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 680,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 681,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 682,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 683,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 684,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 685,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 686,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 687,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 688,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 689,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 690,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 691,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 692,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 693,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 694,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 695,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 696,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 697,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 698,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 699,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 700,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 701,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 702,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 703,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 704,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 705,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 706,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 707,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 708,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 709,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 710,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 711,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 712,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 713,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 714,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 715,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 716,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 717,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 718,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 719,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Blue Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 720,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 721,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 722,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 723,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 724,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 725,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 726,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 727,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 728,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 729,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 730,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 731,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 732,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 733,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 734,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 735,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 736,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 737,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 738,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 739,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 740,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 741,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 742,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 743,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 744,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 745,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 746,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 747,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 748,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 749,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 750,
        "Character": "Tabbi",
        "Body Color": "Red Devil",
        "Speech Bubble": "",
        "Right Arm": "Red Devil Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Red Devil Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Red Devil Heavy Metal",
        "Right Leg": "Red Devil Ruby Slipper",
        "Left Leg": "Red Devil Ruby Slipper",
        "Celestial Attribute": "Red Devil Tail",
        "Background": "Orange Special"
    },
    {
        "ID": 751,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 752,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 753,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 754,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 755,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 756,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 757,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 758,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 759,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 760,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 761,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 762,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 763,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 764,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 765,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 766,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 767,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 768,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 769,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 770,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 771,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 772,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 773,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 774,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 775,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 776,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 777,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 778,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 779,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 780,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 781,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 782,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 783,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 784,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 785,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 786,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 787,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 788,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 789,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 790,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 791,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 792,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 793,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 794,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 795,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 796,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 797,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 798,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 799,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 800,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 801,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 802,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 803,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 804,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 805,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 806,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 807,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 808,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 809,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 810,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 811,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 812,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 813,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 814,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 815,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 816,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 817,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 818,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 819,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 820,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 821,
        "Character": "Tabbi",
        "Body Color": "Gold",
        "Speech Bubble": "",
        "Right Arm": "Gold Fan",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Gold Knife",
        "Right Leg": "Gold Standing",
        "Left Leg": "Gold Pigeon",
        "Celestial Attribute": "Gold Wings",
        "Background": "Dark Gray Special"
    },
    {
        "ID": 822,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 823,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 824,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 825,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 826,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 827,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 828,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 829,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 830,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 831,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 832,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 833,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 834,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 835,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 836,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 837,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 838,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 839,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 840,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 841,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 842,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 843,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 844,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 845,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 846,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 847,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 848,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 849,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 850,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 851,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 852,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 853,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 854,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 855,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 856,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 857,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 858,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 859,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 860,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 861,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 862,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 863,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 864,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 865,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 866,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 867,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 868,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 869,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 870,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 871,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 872,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 873,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 874,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 875,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 876,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 877,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 878,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 879,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 880,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 881,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 882,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 883,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 884,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 885,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 886,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 887,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 888,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 889,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 890,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 891,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 892,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 893,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 894,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 895,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 896,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 897,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 898,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 899,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 900,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 901,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 902,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 903,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 904,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 905,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 906,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 907,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 908,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 909,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 910,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 911,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Red Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 912,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 913,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 914,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 915,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 916,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 917,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 918,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 919,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 920,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 921,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 922,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 923,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 924,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 925,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 926,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 927,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 928,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 929,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 930,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 931,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 932,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 933,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 934,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 935,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 936,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 937,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 938,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 939,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 940,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 941,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 942,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 943,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 944,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 945,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 946,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 947,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 948,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 949,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 950,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 951,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 952,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 953,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 954,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 955,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 956,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 957,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 958,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 959,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 960,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 961,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 962,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 963,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 964,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 965,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 966,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 967,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 968,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 969,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 970,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 971,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 972,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 973,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 974,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 975,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 976,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 977,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 978,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 979,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 980,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 981,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 982,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 983,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 984,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 985,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 986,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Red Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 987,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 988,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 989,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 990,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 991,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 992,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 993,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 994,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 995,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 996,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 997,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 998,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 999,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1000,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1001,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Red Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1002,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1003,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1004,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1005,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1006,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1007,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1008,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1009,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1010,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1011,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Peace",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1012,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1013,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1014,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1015,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1016,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1017,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 1018,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1019,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1020,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1021,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 1022,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1023,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1024,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1025,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1026,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1027,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1028,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1029,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1030,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1031,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1032,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1033,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1034,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1035,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1036,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1037,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1038,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1039,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1040,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1041,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1042,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1043,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1044,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Blue"
    },
    {
        "ID": 1045,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1046,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1047,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1048,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1049,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1050,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1051,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1052,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1053,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1054,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1055,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1056,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1057,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1058,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1059,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1060,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1061,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1062,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1063,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1064,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1065,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1066,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1067,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1068,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1069,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1070,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1071,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1072,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1073,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1074,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1075,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1076,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1077,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1078,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1079,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1080,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1081,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1082,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1083,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1084,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1085,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1086,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1087,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1088,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1089,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1090,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1091,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1092,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1093,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1094,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1095,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1096,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1097,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1098,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1099,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1100,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1101,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1102,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1103,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1104,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1105,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1106,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1107,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1108,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 1109,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1110,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1111,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1112,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1113,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1114,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1115,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1116,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1117,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1118,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1119,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1120,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1121,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1122,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 1123,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1124,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 1125,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1126,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1127,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1128,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1129,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1130,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1131,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1132,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1133,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1134,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1135,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1136,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1137,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1138,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1139,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1140,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1141,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1142,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1143,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1144,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1145,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1146,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1147,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1148,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 1149,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1150,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1151,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1152,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1153,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1154,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1155,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1156,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1157,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1158,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1159,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1160,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1161,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1162,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1163,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1164,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1165,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1166,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1167,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1168,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1169,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1170,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1171,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1172,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1173,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1174,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1175,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1176,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1177,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1178,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1179,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1180,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 1181,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1182,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1183,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1184,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1185,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1186,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1187,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1188,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1189,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1190,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1191,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1192,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1193,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1194,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1195,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1196,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1197,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1198,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1199,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1200,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1201,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1202,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1203,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1204,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1205,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1206,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1207,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1208,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1209,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1210,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1211,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1212,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1213,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1214,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1215,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1216,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1217,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1218,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1219,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1220,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1221,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1222,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1223,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1224,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1225,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1226,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1227,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1228,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1229,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1230,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1231,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1232,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1233,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1234,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1235,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1236,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1237,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1238,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1239,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1240,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1241,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1242,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1243,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1244,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1245,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1246,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1247,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1248,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1249,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1250,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1251,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1252,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1253,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 1254,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1255,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1256,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1257,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1258,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1259,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 1260,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1261,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1262,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1263,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1264,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1265,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1266,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1267,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1268,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1269,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 1270,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1271,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1272,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1273,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1274,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1275,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1276,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1277,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1278,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1279,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1280,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1281,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1282,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1283,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1284,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1285,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1286,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1287,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1288,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1289,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1290,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1291,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1292,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1293,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1294,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1295,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1296,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1297,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1298,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1299,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1300,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1301,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1302,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1303,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1304,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1305,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1306,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1307,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1308,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1309,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1310,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1311,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1312,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1313,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Purple"
    },
    {
        "ID": 1314,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Yellow"
    },
    {
        "ID": 1315,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1316,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1317,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1318,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1319,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1320,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1321,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1322,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1323,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1324,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1325,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1326,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 1327,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1328,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1329,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1330,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1331,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1332,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1333,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1334,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1335,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1336,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1337,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1338,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 1339,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1340,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1341,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1342,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1343,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1344,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 1345,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1346,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1347,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1348,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1349,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1350,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1351,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1352,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1353,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1354,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1355,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1356,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1357,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1358,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1359,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1360,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1361,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1362,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1363,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1364,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1365,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1366,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1367,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1368,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1369,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1370,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1371,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1372,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1373,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1374,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1375,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1376,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1377,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1378,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1379,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1380,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1381,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1382,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1383,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1384,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1385,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1386,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1387,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1388,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1389,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1390,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 1391,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1392,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1393,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1394,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1395,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1396,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1397,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1398,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1399,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1400,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1401,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1402,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1403,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1404,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1405,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1406,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1407,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1408,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1409,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1410,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1411,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1412,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1413,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1414,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1415,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1416,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1417,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1418,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1419,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1420,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1421,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1422,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1423,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1424,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1425,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1426,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1427,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1428,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1429,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Pitchfork",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1430,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1431,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1432,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1433,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1434,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 1435,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1436,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1437,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1438,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1439,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1440,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1441,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1442,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1443,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1444,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1445,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1446,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1447,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1448,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1449,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1450,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1451,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1452,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1453,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1454,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1455,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1456,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1457,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1458,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1459,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1460,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Gray"
    },
    {
        "ID": 1461,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1462,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1463,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1464,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1465,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1466,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1467,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1468,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1469,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1470,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 1471,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1472,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1473,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1474,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1475,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1476,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1477,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1478,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1479,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1480,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1481,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1482,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1483,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1484,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1485,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1486,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1487,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 1488,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1489,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1490,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1491,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1492,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 1493,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1494,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1495,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1496,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1497,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1498,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1499,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1500,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1501,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1502,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1503,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1504,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1505,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1506,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1507,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1508,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Green Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1509,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1510,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1511,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1512,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1513,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1514,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1515,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1516,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1517,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1518,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1519,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1520,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1521,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1522,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1523,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1524,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Yellow"
    },
    {
        "ID": 1525,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1526,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1527,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1528,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1529,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1530,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1531,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1532,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1533,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1534,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1535,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1536,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1537,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1538,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1539,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1540,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1541,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1542,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1543,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1544,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1545,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1546,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1547,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1548,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1549,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1550,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1551,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1552,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1553,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1554,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1555,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1556,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1557,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1558,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1559,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1560,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1561,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1562,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1563,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1564,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1565,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1566,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1567,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1568,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1569,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1570,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1571,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Green Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1572,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1573,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1574,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1575,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1576,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1577,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1578,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1579,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1580,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1581,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1582,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1583,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1584,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1585,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1586,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1587,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1588,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1589,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1590,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1591,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 1592,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1593,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1594,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1595,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1596,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1597,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1598,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1599,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1600,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1601,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1602,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1603,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1604,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1605,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 1606,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1607,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1608,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1609,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1610,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1611,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1612,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1613,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1614,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1615,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 1616,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1617,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1618,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1619,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1620,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 1621,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 1622,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1623,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1624,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1625,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1626,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1627,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1628,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1629,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1630,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1631,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 1632,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1633,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1634,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1635,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1636,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1637,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1638,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1639,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1640,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1641,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1642,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1643,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 1644,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1645,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1646,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1647,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1648,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1649,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1650,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1651,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1652,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1653,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1654,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1655,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1656,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1657,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1658,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1659,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1660,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1661,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1662,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 1663,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1664,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1665,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1666,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1667,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1668,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1669,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1670,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1671,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1672,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1673,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1674,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1675,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1676,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1677,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1678,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1679,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1680,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1681,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1682,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Red Skate",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 1683,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1684,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1685,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1686,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1687,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1688,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1689,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1690,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1691,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1692,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1693,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1694,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1695,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1696,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1697,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1698,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1699,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1700,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1701,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1702,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1703,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1704,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1705,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1706,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1707,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1708,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1709,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1710,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1711,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1712,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1713,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1714,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 1715,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1716,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1717,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1718,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1719,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1720,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1721,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1722,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1723,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1724,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1725,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Gray"
    },
    {
        "ID": 1726,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1727,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1728,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1729,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1730,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1731,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1732,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1733,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1734,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1735,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1736,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1737,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1738,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1739,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1740,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1741,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1742,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1743,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1744,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1745,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1746,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1747,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1748,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1749,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 1750,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1751,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1752,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1753,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1754,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1755,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1756,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1757,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1758,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 1759,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1760,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1761,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1762,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1763,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1764,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1765,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1766,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1767,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1768,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1769,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1770,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1771,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1772,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1773,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1774,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1775,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1776,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1777,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1778,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1779,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1780,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1781,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1782,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1783,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1784,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1785,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1786,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1787,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1788,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1789,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1790,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1791,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1792,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1793,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1794,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1795,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Green Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1796,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1797,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 1798,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1799,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1800,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1801,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1802,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1803,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 1804,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1805,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1806,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1807,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1808,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1809,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1810,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1811,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1812,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1813,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1814,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1815,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1816,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1817,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1818,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1819,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1820,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1821,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1822,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1823,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1824,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1825,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1826,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1827,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1828,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1829,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1830,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1831,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1832,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1833,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Gray"
    },
    {
        "ID": 1834,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1835,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1836,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1837,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1838,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1839,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1840,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1841,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1842,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1843,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1844,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1845,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1846,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1847,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 1848,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1849,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1850,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1851,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1852,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1853,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1854,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 1855,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1856,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 1857,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1858,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1859,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1860,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1861,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1862,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1863,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1864,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1865,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1866,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1867,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1868,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1869,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1870,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1871,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1872,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1873,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1874,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1875,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1876,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1877,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1878,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1879,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1880,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1881,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1882,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1883,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1884,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1885,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1886,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1887,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1888,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1889,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1890,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1891,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1892,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1893,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1894,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1895,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1896,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1897,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1898,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1899,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1900,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1901,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1902,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1903,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1904,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1905,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1906,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1907,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1908,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1909,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1910,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1911,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1912,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1913,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1914,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1915,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1916,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1917,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1918,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1919,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1920,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1921,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1922,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1923,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1924,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1925,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1926,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1927,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1928,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1929,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1930,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1931,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1932,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1933,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1934,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1935,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1936,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1937,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1938,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1939,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1940,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1941,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 1942,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1943,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1944,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1945,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1946,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1947,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1948,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1949,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1950,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1951,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1952,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1953,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1954,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1955,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1956,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1957,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1958,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1959,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1960,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 1961,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1962,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 1963,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1964,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1965,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1966,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1967,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1968,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1969,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1970,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1971,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 1972,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 1973,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 1974,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1975,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1976,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 1977,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 1978,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1979,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1980,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1981,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1982,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 1983,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1984,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1985,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1986,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1987,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 1988,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 1989,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1990,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 1991,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1992,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1993,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Purple"
    },
    {
        "ID": 1994,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 1995,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 1996,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 1997,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 1998,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 1999,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2000,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2001,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2002,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2003,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2004,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2005,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2006,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2007,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 2008,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2009,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2010,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2011,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2012,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2013,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2014,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2015,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2016,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2017,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2018,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2019,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2020,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2021,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2022,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2023,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2024,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2025,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2026,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2027,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2028,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2029,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2030,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2031,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2032,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2033,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 2034,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2035,
        "Character": "Syrin",
        "Body Color": "Murdered Out",
        "Speech Bubble": "",
        "Right Arm": "Murdered Out Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Murdered Out Bag",
        "Right Leg": "Running with Bandage Murdered",
        "Left Leg": "Murdered Out Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue Special"
    },
    {
        "ID": 2036,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Red Skate",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2037,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2038,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2039,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2040,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2041,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2042,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2043,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2044,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2045,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2046,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2047,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2048,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2049,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2050,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2051,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 2052,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2053,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2054,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2055,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 2056,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2057,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2058,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2059,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2060,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2061,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2062,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2063,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2064,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2065,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2066,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2067,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2068,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2069,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2070,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2071,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 2072,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2073,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2074,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 2075,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2076,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2077,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2078,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2079,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2080,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2081,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2082,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2083,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2084,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2085,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2086,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2087,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2088,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2089,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2090,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2091,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2092,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2093,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2094,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2095,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2096,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2097,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2098,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2099,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2100,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2101,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2102,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2103,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2104,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2105,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2106,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2107,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2108,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2109,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2110,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2111,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2112,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2113,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2114,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2115,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2116,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2117,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2118,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2119,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2120,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2121,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2122,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2123,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2124,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2125,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2126,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2127,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2128,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2129,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2130,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2131,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2132,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2133,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2134,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2135,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2136,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2137,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2138,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2139,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2140,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2141,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2142,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2143,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2144,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2145,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2146,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2147,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2148,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2149,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2150,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2151,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2152,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2153,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "Wings",
        "Background": "Pink"
    },
    {
        "ID": 2154,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2155,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2156,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2157,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2158,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2159,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2160,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2161,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2162,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2163,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2164,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2165,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2166,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2167,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2168,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2169,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2170,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2171,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2172,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2173,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2174,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2175,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2176,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2177,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2178,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2179,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2180,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2181,
        "Character": "Rex",
        "Body Color": "Gold",
        "Speech Bubble": "",
        "Right Arm": "Gold Fist",
        "Headwear": "Gold Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Gold Knife",
        "Right Leg": "Gold Cast",
        "Left Leg": "Gold Standing",
        "Celestial Attribute": "Gold Wings",
        "Background": "Dark Gray Special"
    },
    {
        "ID": 2182,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2183,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2184,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 2185,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2186,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2187,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2188,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2189,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2190,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2191,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2192,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2193,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2194,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2195,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2196,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2197,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2198,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2199,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2200,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2201,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2202,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2203,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2204,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2205,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2206,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2207,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2208,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2209,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2210,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2211,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2212,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2213,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2214,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2215,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2216,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2217,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2218,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2219,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2220,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2221,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2222,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2223,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2224,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2225,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2226,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2227,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2228,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2229,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2230,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2231,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2232,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2233,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2234,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2235,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2236,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2237,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2238,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2239,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2240,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2241,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 2242,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2243,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2244,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2245,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2246,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2247,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2248,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2249,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2250,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2251,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2252,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2253,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2254,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2255,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2256,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2257,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2258,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2259,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2260,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2261,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2262,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2263,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2264,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2265,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 2266,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2267,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 2268,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2269,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2270,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2271,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2272,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2273,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2274,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2275,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2276,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2277,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 2278,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2279,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2280,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2281,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2282,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2283,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2284,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2285,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2286,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2287,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2288,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2289,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 2290,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2291,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2292,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2293,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2294,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2295,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2296,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 2297,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2298,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2299,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2300,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2301,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2302,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2303,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2304,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2305,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2306,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2307,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2308,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2309,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2310,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2311,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 2312,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 2313,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2314,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Light Gray"
    },
    {
        "ID": 2315,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2316,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2317,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2318,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2319,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2320,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2321,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2322,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2323,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2324,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2325,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2326,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2327,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2328,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2329,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2330,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2331,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2332,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2333,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2334,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2335,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2336,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2337,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2338,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2339,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2340,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2341,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2342,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2343,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2344,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2345,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2346,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2347,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2348,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2349,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2350,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2351,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2352,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2353,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2354,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 2355,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2356,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2357,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2358,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2359,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2360,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2361,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2362,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2363,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2364,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2365,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2366,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2367,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2368,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2369,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2370,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2371,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 2372,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2373,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2374,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2375,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2376,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2377,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 2378,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2379,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2380,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2381,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2382,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2383,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2384,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2385,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2386,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2387,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2388,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bandage",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2389,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2390,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2391,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2392,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2393,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2394,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2395,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2396,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2397,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2398,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2399,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 2400,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2401,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2402,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2403,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2404,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2405,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2406,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2407,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2408,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2409,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2410,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2411,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2412,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2413,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2414,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2415,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2416,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 2417,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2418,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2419,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2420,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2421,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2422,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2423,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2424,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2425,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2426,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2427,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2428,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2429,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2430,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2431,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2432,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2433,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2434,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2435,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2436,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2437,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2438,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2439,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2440,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2441,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2442,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2443,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2444,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2445,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2446,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2447,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2448,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2449,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2450,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2451,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2452,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2453,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2454,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2455,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2456,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2457,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2458,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2459,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2460,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2461,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2462,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2463,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2464,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2465,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2466,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2467,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2468,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2469,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2470,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2471,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 2472,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2473,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2474,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2475,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2476,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2477,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2478,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2479,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 2480,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2481,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2482,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2483,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2484,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2485,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2486,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2487,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2488,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2489,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2490,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 2491,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2492,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2493,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2494,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2495,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2496,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Pink"
    },
    {
        "ID": 2497,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2498,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2499,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2500,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2501,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2502,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2503,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2504,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2505,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2506,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2507,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2508,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2509,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2510,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2511,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2512,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2513,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2514,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 2515,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2516,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2517,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2518,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2519,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2520,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2521,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2522,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2523,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2524,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2525,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2526,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2527,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2528,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2529,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2530,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2531,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2532,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2533,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2534,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2535,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2536,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2537,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Red Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2538,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2539,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2540,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2541,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2542,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2543,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2544,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2545,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2546,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2547,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2548,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2549,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2550,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2551,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2552,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2553,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2554,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2555,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2556,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2557,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2558,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2559,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2560,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2561,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2562,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2563,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2564,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2565,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2566,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2567,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2568,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2569,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2570,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2571,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2572,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2573,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2574,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2575,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2576,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2577,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2578,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2579,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2580,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2581,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2582,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2583,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2584,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2585,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2586,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2587,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2588,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2589,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2590,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2591,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2592,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2593,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2594,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2595,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2596,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2597,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2598,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2599,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2600,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2601,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2602,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2603,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2604,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2605,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2606,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2607,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2608,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2609,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2610,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2611,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2612,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2613,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2614,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2615,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2616,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2617,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Purple"
    },
    {
        "ID": 2618,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2619,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2620,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2621,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2622,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2623,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2624,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2625,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2626,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2627,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 2628,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2629,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2630,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2631,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2632,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2633,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2634,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2635,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2636,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2637,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2638,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2639,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2640,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2641,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2642,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2643,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2644,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2645,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2646,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2647,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2648,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2649,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2650,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2651,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2652,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 2653,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2654,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2655,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2656,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2657,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2658,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2659,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2660,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2661,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2662,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2663,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2664,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red Wine"
    },
    {
        "ID": 2665,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2666,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 2667,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2668,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2669,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2670,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2671,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2672,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2673,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2674,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2675,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2676,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2677,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2678,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2679,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2680,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2681,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2682,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2683,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2684,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2685,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2686,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2687,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2688,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2689,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2690,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2691,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2692,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2693,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2694,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2695,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2696,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2697,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2698,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2699,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2700,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2701,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2702,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2703,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2704,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2705,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 2706,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2707,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2708,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2709,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2710,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Pink"
    },
    {
        "ID": 2711,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2712,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2713,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2714,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2715,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2716,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2717,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2718,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2719,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2720,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 2721,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Red Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2722,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2723,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2724,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2725,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2726,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Gray"
    },
    {
        "ID": 2727,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2728,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2729,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2730,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2731,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2732,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2733,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2734,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2735,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2736,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2737,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2738,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2739,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2740,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2741,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2742,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2743,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2744,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2745,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2746,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2747,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2748,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2749,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2750,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 2751,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2752,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2753,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2754,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2755,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2756,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2757,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2758,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2759,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2760,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2761,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2762,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2763,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2764,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2765,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2766,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2767,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 2768,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2769,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2770,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2771,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2772,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2773,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2774,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2775,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2776,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2777,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2778,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2779,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2780,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 2781,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2782,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2783,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2784,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2785,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2786,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2787,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2788,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 2789,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2790,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2791,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2792,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2793,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2794,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2795,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2796,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2797,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2798,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2799,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2800,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2801,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2802,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2803,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2804,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2805,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2806,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2807,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2808,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2809,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2810,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2811,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2812,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2813,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2814,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2815,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2816,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2817,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2818,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2819,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2820,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2821,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2822,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2823,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2824,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2825,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2826,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2827,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2828,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2829,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2830,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2831,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2832,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2833,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2834,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2835,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2836,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2837,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2838,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2839,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2840,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2841,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2842,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2843,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2844,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2845,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2846,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2847,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2848,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2849,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2850,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2851,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2852,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2853,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2854,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2855,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2856,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2857,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2858,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2859,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2860,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2861,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2862,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2863,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2864,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2865,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2866,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2867,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2868,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2869,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2870,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2871,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2872,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2873,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2874,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2875,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2876,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2877,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2878,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2879,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2880,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2881,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2882,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2883,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2884,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2885,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2886,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2887,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2888,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2889,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2890,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2891,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2892,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2893,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2894,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2895,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2896,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2897,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2898,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2899,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2900,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2901,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2902,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 2903,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2904,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2905,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2906,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 2907,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2908,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2909,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2910,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2911,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2912,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2913,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2914,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2915,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2916,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2917,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2918,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2919,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2920,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 2921,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 2922,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2923,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 2924,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2925,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2926,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2927,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2928,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2929,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2930,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2931,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2932,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2933,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2934,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2935,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 2936,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2937,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2938,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2939,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2940,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2941,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2942,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2943,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2944,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2945,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2946,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2947,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2948,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 2949,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2950,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2951,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2952,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2953,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2954,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2955,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2956,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2957,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2958,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2959,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2960,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2961,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2962,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2963,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2964,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2965,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2966,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 2967,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2968,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2969,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2970,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2971,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2972,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2973,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2974,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2975,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2976,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2977,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2978,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2979,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2980,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2981,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2982,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 2983,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 2984,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 2985,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2986,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 2987,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 2988,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2989,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Purple"
    },
    {
        "ID": 2990,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2991,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 2992,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 2993,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 2994,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2995,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 2996,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 2997,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 2998,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 2999,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3000,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3001,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3002,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3003,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3004,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3005,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3006,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3007,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3008,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3009,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3010,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3011,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3012,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3013,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3014,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3015,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3016,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3017,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3018,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3019,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3020,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3021,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3022,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3023,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3024,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3025,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3026,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3027,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3028,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3029,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3030,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3031,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3032,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3033,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 3034,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3035,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3036,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3037,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3038,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3039,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3040,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3041,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3042,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3043,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3044,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3045,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3046,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3047,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3048,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3049,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3050,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3051,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3052,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3053,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3054,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3055,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3056,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 3057,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3058,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3059,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3060,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3061,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3062,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3063,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3064,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3065,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3066,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3067,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3068,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3069,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3070,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3071,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3072,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3073,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3074,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3075,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3076,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3077,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3078,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3079,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3080,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3081,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3082,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3083,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3084,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3085,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3086,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3087,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3088,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 3089,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3090,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3091,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3092,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3093,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3094,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3095,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Purple"
    },
    {
        "ID": 3096,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3097,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3098,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3099,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3100,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3101,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3102,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3103,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3104,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3105,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3106,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3107,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3108,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3109,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3110,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3111,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3112,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3113,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3114,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3115,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3116,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3117,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3118,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 3119,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3120,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Red Skate",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3121,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3122,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3123,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3124,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3125,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3126,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3127,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3128,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3129,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3130,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3131,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3132,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3133,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3134,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3135,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3136,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3137,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3138,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3139,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3140,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3141,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3142,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3143,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3144,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3145,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3146,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3147,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3148,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3149,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3150,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3151,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3152,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3153,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3154,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 3155,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3156,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3157,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3158,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3159,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3160,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3161,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3162,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3163,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3164,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3165,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3166,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3167,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3168,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3169,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3170,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3171,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3172,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3173,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3174,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3175,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3176,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3177,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3178,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3179,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3180,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3181,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3182,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3183,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3184,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3185,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3186,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3187,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3188,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3189,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3190,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3191,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3192,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3193,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3194,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3195,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3196,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3197,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3198,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3199,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3200,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 3201,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3202,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3203,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3204,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3205,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3206,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3207,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3208,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3209,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3210,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3211,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3212,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3213,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3214,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Gray"
    },
    {
        "ID": 3215,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3216,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3217,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3218,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3219,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3220,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3221,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3222,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3223,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3224,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3225,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3226,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3227,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3228,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3229,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3230,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3231,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3232,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3233,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3234,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3235,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3236,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3237,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3238,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3239,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 3240,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3241,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3242,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3243,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3244,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3245,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 3246,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3247,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3248,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3249,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3250,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3251,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Light Gray"
    },
    {
        "ID": 3252,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3253,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3254,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3255,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3256,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3257,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3258,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3259,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3260,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3261,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3262,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3263,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 3264,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3265,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3266,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3267,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3268,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3269,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3270,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3271,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3272,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 3273,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3274,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3275,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3276,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3277,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3278,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3279,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3280,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3281,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3282,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3283,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3284,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3285,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3286,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3287,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3288,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3289,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3290,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3291,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3292,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3293,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3294,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3295,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3296,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3297,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3298,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3299,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3300,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3301,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3302,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3303,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3304,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Red Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3305,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3306,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3307,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3308,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3309,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3310,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3311,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3312,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3313,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3314,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3315,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3316,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3317,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3318,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3319,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3320,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3321,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3322,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3323,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3324,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3325,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3326,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3327,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3328,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3329,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3330,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3331,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3332,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3333,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3334,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3335,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3336,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3337,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3338,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3339,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3340,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3341,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3342,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3343,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3344,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3345,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3346,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3347,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3348,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3349,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3350,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3351,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3352,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3353,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3354,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3355,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3356,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3357,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3358,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3359,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3360,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3361,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3362,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3363,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3364,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3365,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3366,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3367,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3368,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3369,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3370,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3371,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3372,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3373,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3374,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3375,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3376,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3377,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3378,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3379,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3380,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3381,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3382,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3383,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3384,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3385,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3386,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3387,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3388,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3389,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3390,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3391,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3392,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3393,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3394,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3395,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3396,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3397,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3398,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3399,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3400,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3401,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3402,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3403,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3404,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3405,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3406,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3407,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3408,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3409,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3410,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3411,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3412,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 3413,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3414,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3415,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3416,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3417,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3418,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3419,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3420,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3421,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3422,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3423,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3424,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3425,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3426,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 3427,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3428,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3429,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3430,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3431,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3432,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3433,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3434,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Purple"
    },
    {
        "ID": 3435,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3436,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3437,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3438,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3439,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red Wine"
    },
    {
        "ID": 3440,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3441,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3442,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3443,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3444,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3445,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Purple"
    },
    {
        "ID": 3446,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3447,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3448,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3449,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3450,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 3451,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3452,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3453,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3454,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3455,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3456,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3457,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3458,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3459,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3460,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3461,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3462,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3463,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3464,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3465,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3466,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3467,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3468,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 3469,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3470,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3471,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3472,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3473,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Red Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3474,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3475,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3476,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3477,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3478,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3479,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3480,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3481,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3482,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3483,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3484,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3485,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3486,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3487,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3488,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3489,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3490,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3491,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3492,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3493,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3494,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3495,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3496,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3497,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3498,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3499,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Yellow"
    },
    {
        "ID": 3500,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3501,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3502,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3503,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3504,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3505,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3506,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3507,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3508,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3509,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3510,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3511,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3512,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3513,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 3514,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3515,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3516,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3517,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3518,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3519,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 3520,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3521,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 3522,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3523,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3524,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3525,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3526,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3527,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3528,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3529,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3530,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3531,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3532,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3533,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3534,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3535,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3536,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Gray"
    },
    {
        "ID": 3537,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3538,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3539,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3540,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3541,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Pink"
    },
    {
        "ID": 3542,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3543,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3544,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3545,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3546,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3547,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3548,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3549,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3550,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3551,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3552,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3553,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3554,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3555,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3556,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3557,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3558,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3559,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3560,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3561,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3562,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3563,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3564,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3565,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3566,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3567,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3568,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3569,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3570,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3571,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3572,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3573,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3574,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3575,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3576,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3577,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3578,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 3579,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3580,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3581,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3582,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3583,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3584,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3585,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 3586,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3587,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 3588,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3589,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3590,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3591,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3592,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3593,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3594,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3595,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3596,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3597,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3598,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3599,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3600,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3601,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3602,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3603,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3604,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 3605,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3606,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3607,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3608,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3609,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3610,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3611,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3612,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 3613,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3614,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3615,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3616,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3617,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3618,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3619,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3620,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3621,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3622,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3623,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3624,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3625,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3626,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3627,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3628,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3629,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3630,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3631,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3632,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3633,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3634,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3635,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3636,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3637,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3638,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3639,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3640,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3641,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3642,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3643,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3644,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3645,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3646,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3647,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3648,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3649,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3650,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3651,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3652,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3653,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3654,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3655,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3656,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3657,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3658,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 3659,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3660,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3661,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3662,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3663,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3664,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3665,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3666,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3667,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3668,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3669,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3670,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3671,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3672,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3673,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3674,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 3675,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3676,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3677,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3678,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3679,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3680,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3681,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3682,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3683,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3684,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3685,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3686,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3687,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3688,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3689,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3690,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3691,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3692,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3693,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3694,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3695,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3696,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3697,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3698,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3699,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3700,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3701,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 3702,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3703,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3704,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3705,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3706,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3707,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3708,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3709,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3710,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3711,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3712,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3713,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3714,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3715,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3716,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3717,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3718,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3719,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3720,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3721,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3722,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3723,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3724,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3725,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3726,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3727,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3728,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3729,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3730,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3731,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3732,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3733,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3734,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3735,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3736,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3737,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3738,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3739,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3740,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3741,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3742,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3743,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3744,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3745,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3746,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3747,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3748,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3749,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3750,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3751,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3752,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3753,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3754,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3755,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3756,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3757,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3758,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3759,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3760,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3761,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3762,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3763,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3764,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3765,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3766,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3767,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3768,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3769,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3770,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3771,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3772,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3773,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3774,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3775,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3776,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3777,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3778,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3779,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3780,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3781,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 3782,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3783,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3784,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3785,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3786,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3787,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3788,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3789,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3790,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 3791,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3792,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3793,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3794,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3795,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3796,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3797,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3798,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3799,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3800,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3801,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3802,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3803,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3804,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3805,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3806,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 3807,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Yellow"
    },
    {
        "ID": 3808,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 3809,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 3810,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3811,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3812,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3813,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3814,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3815,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3816,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3817,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3818,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3819,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3820,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3821,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3822,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3823,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3824,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3825,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Blue"
    },
    {
        "ID": 3826,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3827,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3828,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3829,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3830,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3831,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3832,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3833,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3834,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3835,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3836,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3837,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3838,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3839,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3840,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3841,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3842,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3843,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3844,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3845,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3846,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3847,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3848,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3849,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3850,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3851,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3852,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3853,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3854,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3855,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3856,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3857,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3858,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3859,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3860,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3861,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3862,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3863,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3864,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3865,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3866,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3867,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3868,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3869,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 3870,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3871,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3872,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3873,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3874,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3875,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3876,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3877,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3878,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3879,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3880,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3881,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3882,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3883,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3884,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3885,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3886,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3887,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3888,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3889,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3890,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3891,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3892,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3893,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3894,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3895,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3896,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3897,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3898,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3899,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3900,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3901,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3902,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3903,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3904,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3905,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3906,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3907,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3908,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3909,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3910,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3911,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3912,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3913,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3914,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3915,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3916,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3917,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3918,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3919,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3920,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3921,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3922,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3923,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3924,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3925,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3926,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3927,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3928,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3929,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3930,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3931,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3932,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3933,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3934,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3935,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3936,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3937,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3938,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3939,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3940,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3941,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3942,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3943,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3944,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3945,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 3946,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3947,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3948,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3949,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3950,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3951,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3952,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3953,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3954,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3955,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3956,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3957,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3958,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3959,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3960,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 3961,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3962,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3963,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3964,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3965,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3966,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3967,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3968,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3969,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3970,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 3971,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3972,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3973,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3974,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3975,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3976,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3977,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3978,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3979,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 3980,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3981,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3982,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 3983,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 3984,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3985,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Peace",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 3986,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 3987,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3988,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 3989,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 3990,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3991,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3992,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 3993,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 3994,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 3995,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 3996,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3997,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 3998,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 3999,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4000,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4001,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4002,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4003,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4004,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4005,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4006,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4007,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4008,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4009,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4010,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4011,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4012,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4013,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 4014,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4015,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4016,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4017,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4018,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4019,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4020,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4021,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4022,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4023,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4024,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4025,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4026,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4027,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4028,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4029,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4030,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 4031,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4032,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4033,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4034,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4035,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Gray"
    },
    {
        "ID": 4036,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4037,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4038,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4039,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4040,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4041,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4042,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4043,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4044,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4045,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4046,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4047,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4048,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4049,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 4050,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4051,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4052,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4053,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4054,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4055,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4056,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4057,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4058,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4059,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4060,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4061,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4062,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4063,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4064,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4065,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4066,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4067,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4068,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4069,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4070,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4071,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4072,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4073,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4074,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4075,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4076,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4077,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4078,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4079,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4080,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4081,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4082,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4083,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4084,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4085,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4086,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4087,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4088,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4089,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4090,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4091,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4092,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4093,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4094,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4095,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4096,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4097,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4098,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4099,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4100,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4101,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4102,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4103,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4104,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4105,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4106,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4107,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4108,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4109,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4110,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4111,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4112,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4113,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4114,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4115,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4116,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4117,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4118,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4119,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Gray"
    },
    {
        "ID": 4120,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4121,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4122,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4123,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 4124,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4125,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4126,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4127,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Green Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4128,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4129,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Yellow"
    },
    {
        "ID": 4130,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4131,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4132,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4133,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4134,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4135,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4136,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4137,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4138,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4139,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4140,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4141,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4142,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4143,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4144,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4145,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4146,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4147,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4148,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4149,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4150,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4151,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4152,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4153,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4154,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4155,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4156,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4157,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4158,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4159,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4160,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4161,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4162,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4163,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4164,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4165,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4166,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4167,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4168,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4169,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4170,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4171,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4172,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4173,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4174,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4175,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 4176,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4177,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4178,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4179,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4180,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4181,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4182,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4183,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4184,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red"
    },
    {
        "ID": 4185,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4186,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4187,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4188,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4189,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4190,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4191,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4192,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4193,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4194,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4195,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4196,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4197,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4198,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4199,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4200,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4201,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4202,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4203,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4204,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4205,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4206,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4207,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4208,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4209,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4210,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4211,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4212,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4213,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4214,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4215,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4216,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4217,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4218,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4219,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4220,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4221,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4222,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4223,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4224,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4225,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4226,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4227,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4228,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4229,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4230,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4231,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 4232,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4233,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4234,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4235,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4236,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4237,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4238,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4239,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4240,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4241,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4242,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4243,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4244,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4245,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4246,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4247,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4248,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4249,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4250,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4251,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4252,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4253,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4254,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4255,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4256,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4257,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4258,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4259,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4260,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4261,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4262,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4263,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4264,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4265,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4266,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4267,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4268,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4269,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4270,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4271,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4272,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4273,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4274,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4275,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4276,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4277,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4278,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4279,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4280,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4281,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4282,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4283,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4284,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4285,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 4286,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4287,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4288,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4289,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4290,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4291,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4292,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4293,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4294,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4295,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4296,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4297,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4298,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4299,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4300,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4301,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4302,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4303,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4304,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4305,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4306,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4307,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4308,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4309,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4310,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4311,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4312,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4313,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4314,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4315,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4316,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4317,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4318,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4319,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4320,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4321,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4322,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4323,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4324,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4325,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4326,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4327,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4328,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4329,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4330,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4331,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4332,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4333,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4334,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4335,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4336,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4337,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4338,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4339,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4340,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4341,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4342,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4343,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4344,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4345,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4346,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4347,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4348,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4349,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4350,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4351,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4352,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4353,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4354,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4355,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4356,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4357,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4358,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4359,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4360,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4361,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4362,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4363,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4364,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4365,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4366,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4367,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4368,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4369,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4370,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4371,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4372,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4373,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red Wine"
    },
    {
        "ID": 4374,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4375,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4376,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4377,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4378,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4379,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4380,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Red Skate",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 4381,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4382,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4383,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4384,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4385,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4386,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4387,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4388,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4389,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4390,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4391,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4392,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4393,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4394,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4395,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4396,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4397,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4398,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4399,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4400,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4401,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4402,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4403,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4404,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4405,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4406,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4407,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4408,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4409,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4410,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4411,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4412,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4413,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4414,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4415,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4416,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4417,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4418,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4419,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4420,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4421,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4422,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4423,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4424,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4425,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4426,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4427,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 4428,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4429,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4430,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4431,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4432,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 4433,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4434,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4435,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4436,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4437,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4438,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4439,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4440,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4441,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red Wine"
    },
    {
        "ID": 4442,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4443,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4444,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4445,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4446,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4447,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4448,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4449,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4450,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 4451,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4452,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4453,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4454,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4455,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4456,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4457,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4458,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4459,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4460,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4461,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4462,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4463,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4464,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4465,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4466,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4467,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4468,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4469,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4470,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4471,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4472,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4473,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4474,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4475,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4476,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4477,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4478,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 4479,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4480,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Gray"
    },
    {
        "ID": 4481,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4482,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4483,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4484,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4485,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4486,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Red Skate",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 4487,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4488,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4489,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4490,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4491,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4492,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4493,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4494,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4495,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4496,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4497,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4498,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4499,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4500,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4501,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4502,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4503,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4504,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4505,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4506,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4507,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4508,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4509,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4510,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4511,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4512,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4513,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4514,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4515,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4516,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4517,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4518,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4519,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4520,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4521,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Gray"
    },
    {
        "ID": 4522,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4523,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4524,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4525,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4526,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4527,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4528,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4529,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4530,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4531,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4532,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4533,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4534,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4535,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4536,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4537,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4538,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4539,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4540,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4541,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4542,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4543,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Blue"
    },
    {
        "ID": 4544,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4545,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4546,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4547,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4548,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4549,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4550,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4551,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4552,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4553,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4554,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4555,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4556,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4557,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4558,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4559,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4560,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4561,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4562,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4563,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4564,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4565,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4566,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4567,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4568,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4569,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4570,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4571,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4572,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4573,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4574,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4575,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 4576,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4577,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4578,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4579,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4580,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4581,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4582,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4583,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4584,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4585,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4586,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4587,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4588,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4589,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4590,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4591,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4592,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4593,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4594,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4595,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4596,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4597,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4598,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4599,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4600,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4601,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4602,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4603,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4604,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4605,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4606,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4607,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4608,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4609,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4610,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4611,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4612,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4613,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4614,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4615,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4616,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4617,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4618,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4619,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4620,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4621,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4622,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4623,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4624,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4625,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4626,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4627,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4628,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4629,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4630,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4631,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4632,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4633,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4634,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4635,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4636,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4637,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4638,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4639,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4640,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4641,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 4642,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4643,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Bandage",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4644,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4645,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4646,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4647,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4648,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4649,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4650,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4651,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4652,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4653,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4654,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4655,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4656,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4657,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4658,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4659,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4660,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4661,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4662,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4663,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4664,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4665,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4666,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4667,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4668,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4669,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4670,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4671,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4672,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4673,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 4674,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4675,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4676,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4677,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4678,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4679,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4680,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4681,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4682,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4683,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4684,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4685,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4686,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4687,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4688,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4689,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4690,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4691,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4692,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4693,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4694,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4695,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4696,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4697,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4698,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4699,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 4700,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4701,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4702,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4703,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4704,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4705,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4706,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 4707,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4708,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4709,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4710,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4711,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4712,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4713,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4714,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4715,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4716,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4717,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4718,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4719,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4720,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4721,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4722,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4723,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4724,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4725,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4726,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4727,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4728,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4729,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4730,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4731,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4732,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4733,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4734,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4735,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4736,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4737,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4738,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4739,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4740,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4741,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4742,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4743,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4744,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4745,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4746,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4747,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4748,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4749,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4750,
        "Character": "Cap",
        "Body Color": "Red Devil",
        "Speech Bubble": "",
        "Right Arm": "Red Devil Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Red Devil Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Red Devil Heavy Metal",
        "Right Leg": "Red Devil Standing",
        "Left Leg": "Red Devil Standing",
        "Celestial Attribute": "Red Devil Tail",
        "Background": "Orange Special"
    },
    {
        "ID": 4751,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4752,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4753,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4754,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4755,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4756,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4757,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4758,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4759,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4760,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4761,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4762,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4763,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4764,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4765,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4766,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4767,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4768,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4769,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4770,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4771,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 4772,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4773,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4774,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4775,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4776,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4777,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4778,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4779,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4780,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4781,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4782,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4783,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4784,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4785,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4786,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4787,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4788,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4789,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4790,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4791,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4792,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4793,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4794,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4795,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4796,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4797,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4798,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4799,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4800,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4801,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4802,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4803,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 4804,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4805,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4806,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4807,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4808,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4809,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4810,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4811,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4812,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4813,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4814,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4815,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4816,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4817,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4818,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4819,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4820,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4821,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4822,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4823,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4824,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4825,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4826,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4827,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4828,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4829,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4830,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4831,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4832,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4833,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4834,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4835,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4836,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4837,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4838,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4839,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4840,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4841,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4842,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4843,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4844,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4845,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4846,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4847,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4848,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4849,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4850,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4851,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4852,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4853,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4854,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4855,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4856,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4857,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4858,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4859,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4860,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4861,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4862,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4863,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4864,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4865,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4866,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4867,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4868,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4869,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4870,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4871,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4872,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4873,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4874,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4875,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4876,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4877,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4878,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4879,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4880,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4881,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4882,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4883,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4884,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4885,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4886,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4887,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4888,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4889,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4890,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4891,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4892,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4893,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4894,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4895,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4896,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4897,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4898,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4899,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4900,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4901,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4902,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4903,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4904,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4905,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4906,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4907,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4908,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 4909,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4910,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4911,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red Wine"
    },
    {
        "ID": 4912,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4913,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4914,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4915,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4916,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4917,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4918,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 4919,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4920,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4921,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4922,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4923,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4924,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4925,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4926,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4927,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4928,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4929,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4930,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4931,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4932,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4933,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4934,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4935,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4936,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4937,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4938,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4939,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4940,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4941,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4942,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4943,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4944,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4945,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red"
    },
    {
        "ID": 4946,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4947,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4948,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 4949,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4950,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4951,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4952,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4953,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 4954,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4955,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4956,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4957,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4958,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4959,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4960,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 4961,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4962,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4963,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4964,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4965,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4966,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4967,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4968,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4969,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4970,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 4971,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4972,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4973,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4974,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 4975,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4976,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4977,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4978,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4979,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4980,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4981,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 4982,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 4983,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4984,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Gray"
    },
    {
        "ID": 4985,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 4986,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4987,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4988,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 4989,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4990,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 4991,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4992,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 4993,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 4994,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 4995,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 4996,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 4997,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 4998,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 4999,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5000,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5001,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5002,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5003,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5004,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5005,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5006,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5007,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5008,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5009,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5010,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5011,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5012,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5013,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5014,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5015,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5016,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5017,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5018,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Red Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5019,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Red Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5020,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5021,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5022,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5023,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5024,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 5025,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5026,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5027,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5028,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5029,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5030,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5031,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5032,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Purple"
    },
    {
        "ID": 5033,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5034,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5035,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5036,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 5037,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5038,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5039,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5040,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5041,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 5042,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5043,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5044,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5045,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5046,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5047,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5048,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5049,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5050,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5051,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5052,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5053,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 5054,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5055,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 5056,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5057,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5058,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5059,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5060,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5061,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5062,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5063,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Light Gray"
    },
    {
        "ID": 5064,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5065,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5066,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5067,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5068,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5069,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5070,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5071,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5072,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5073,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5074,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5075,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5076,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5077,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5078,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5079,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5080,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5081,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5082,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5083,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5084,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5085,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5086,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5087,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5088,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5089,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5090,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5091,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5092,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Purple"
    },
    {
        "ID": 5093,
        "Character": "Cap",
        "Body Color": "Gold",
        "Speech Bubble": "",
        "Right Arm": "Gold Claw",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Gold Knife",
        "Right Leg": "Gold Standing",
        "Left Leg": "Gold Standing",
        "Celestial Attribute": "Gold Wings",
        "Background": "Dark Gray Special"
    },
    {
        "ID": 5094,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5095,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5096,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5097,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5098,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 5099,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5100,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5101,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5102,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "Devil Tail",
        "Background": "Yellow"
    },
    {
        "ID": 5103,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5104,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5105,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5106,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5107,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5108,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5109,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 5110,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5111,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5112,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5113,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5114,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5115,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5116,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5117,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5118,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5119,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5120,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5121,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5122,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5123,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5124,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5125,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5126,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5127,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5128,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5129,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5130,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5131,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5132,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5133,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5134,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5135,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5136,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5137,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5138,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5139,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5140,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Pitchfork",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5141,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5142,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5143,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5144,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5145,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5146,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5147,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5148,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5149,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5150,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5151,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Yellow"
    },
    {
        "ID": 5152,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5153,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5154,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5155,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 5156,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5157,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5158,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5159,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5160,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5161,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5162,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5163,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5164,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5165,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5166,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5167,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5168,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5169,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5170,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5171,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5172,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5173,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5174,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5175,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5176,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5177,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5178,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5179,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5180,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5181,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5182,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5183,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5184,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5185,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5186,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5187,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5188,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5189,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5190,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5191,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5192,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5193,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5194,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5195,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5196,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5197,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5198,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5199,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5200,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5201,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5202,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5203,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5204,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5205,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5206,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5207,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5208,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5209,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5210,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5211,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5212,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5213,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5214,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5215,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5216,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5217,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5218,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5219,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5220,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5221,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5222,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5223,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5224,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5225,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5226,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5227,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5228,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5229,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5230,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5231,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5232,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5233,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5234,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5235,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5236,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5237,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5238,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5239,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5240,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5241,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5242,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5243,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5244,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5245,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5246,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5247,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5248,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5249,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5250,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5251,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5252,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5253,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5254,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5255,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5256,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5257,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5258,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5259,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5260,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5261,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5262,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 5263,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5264,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5265,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5266,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5267,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5268,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5269,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5270,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5271,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5272,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5273,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5274,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5275,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Blue"
    },
    {
        "ID": 5276,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 5277,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5278,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5279,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5280,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5281,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5282,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5283,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5284,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5285,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5286,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5287,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5288,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5289,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5290,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5291,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5292,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5293,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5294,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5295,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5296,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5297,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5298,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5299,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5300,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5301,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5302,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5303,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5304,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5305,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5306,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5307,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5308,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5309,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5310,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5311,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5312,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5313,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Purple"
    },
    {
        "ID": 5314,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5315,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5316,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5317,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5318,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5319,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5320,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5321,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5322,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5323,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5324,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5325,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5326,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5327,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5328,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5329,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5330,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5331,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5332,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5333,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5334,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5335,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5336,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5337,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5338,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5339,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5340,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5341,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5342,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5343,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5344,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5345,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5346,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5347,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5348,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5349,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5350,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5351,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5352,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5353,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5354,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5355,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5356,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5357,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red"
    },
    {
        "ID": 5358,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5359,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5360,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5361,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5362,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5363,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5364,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5365,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5366,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5367,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5368,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5369,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5370,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5371,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5372,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5373,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5374,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5375,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5376,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5377,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5378,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5379,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5380,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5381,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5382,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5383,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5384,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 5385,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5386,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5387,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5388,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5389,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5390,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Red Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5391,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5392,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5393,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5394,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5395,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5396,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5397,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5398,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5399,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5400,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5401,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5402,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5403,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5404,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5405,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5406,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5407,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5408,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5409,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5410,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5411,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5412,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5413,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5414,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5415,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5416,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5417,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5418,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5419,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5420,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5421,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5422,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5423,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5424,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5425,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5426,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5427,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5428,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5429,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5430,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5431,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5432,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5433,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5434,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5435,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5436,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5437,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5438,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5439,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5440,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5441,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5442,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5443,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5444,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5445,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5446,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5447,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5448,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5449,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5450,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5451,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5452,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 5453,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5454,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 5455,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5456,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5457,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5458,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5459,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5460,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5461,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5462,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5463,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5464,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5465,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5466,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 5467,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5468,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5469,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5470,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5471,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5472,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5473,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5474,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5475,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5476,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5477,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5478,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5479,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5480,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5481,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5482,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5483,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5484,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5485,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5486,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5487,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 5488,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5489,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5490,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5491,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5492,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5493,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5494,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5495,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5496,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 5497,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5498,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5499,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5500,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5501,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5502,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5503,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5504,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5505,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5506,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5507,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5508,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5509,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5510,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5511,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5512,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5513,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5514,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5515,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5516,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5517,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5518,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5519,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5520,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5521,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5522,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5523,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5524,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5525,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5526,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5527,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5528,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5529,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5530,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5531,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5532,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5533,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5534,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5535,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5536,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5537,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5538,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5539,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5540,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5541,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5542,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5543,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5544,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5545,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5546,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5547,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5548,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 5549,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5550,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5551,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5552,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5553,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5554,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5555,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5556,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5557,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5558,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5559,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5560,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5561,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5562,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5563,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5564,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5565,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5566,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5567,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5568,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5569,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5570,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5571,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5572,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5573,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5574,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5575,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5576,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5577,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5578,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5579,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5580,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5581,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5582,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5583,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5584,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5585,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5586,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5587,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5588,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5589,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5590,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5591,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5592,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5593,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5594,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5595,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5596,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5597,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5598,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5599,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5600,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5601,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5602,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5603,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5604,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5605,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5606,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5607,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5608,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5609,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5610,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5611,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5612,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5613,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5614,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5615,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5616,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5617,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5618,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5619,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5620,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5621,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5622,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5623,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5624,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5625,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5626,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5627,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5628,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5629,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5630,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5631,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5632,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5633,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5634,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5635,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5636,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5637,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5638,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5639,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5640,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5641,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5642,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5643,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5644,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5645,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5646,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5647,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5648,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5649,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5650,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5651,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5652,
        "Character": "Syrin",
        "Body Color": "Red Devil",
        "Speech Bubble": "",
        "Right Arm": "Red Devil Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Red Devil Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Red Devil Pitchfork",
        "Right Leg": "Red Devil One Sock",
        "Left Leg": "Red Devil Standing",
        "Celestial Attribute": "Red Devil Tail",
        "Background": "Orange Special"
    },
    {
        "ID": 5653,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5654,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5655,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5656,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5657,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5658,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5659,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5660,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5661,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5662,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5663,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5664,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5665,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5666,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5667,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5668,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5669,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5670,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5671,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5672,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5673,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5674,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5675,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5676,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5677,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5678,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5679,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5680,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5681,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5682,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5683,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5684,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 5685,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5686,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5687,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 5688,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5689,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5690,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5691,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5692,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5693,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5694,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5695,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5696,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5697,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5698,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5699,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5700,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5701,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 5702,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5703,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5704,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5705,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5706,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5707,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5708,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5709,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5710,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5711,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5712,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5713,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5714,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5715,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 5716,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5717,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5718,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5719,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5720,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5721,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5722,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5723,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5724,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5725,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5726,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5727,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5728,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5729,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5730,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5731,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5732,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5733,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5734,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5735,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5736,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5737,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5738,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5739,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5740,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Purple"
    },
    {
        "ID": 5741,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5742,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5743,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5744,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5745,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5746,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5747,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5748,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5749,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5750,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5751,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5752,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5753,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5754,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5755,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5756,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 5757,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5758,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5759,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5760,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5761,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5762,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5763,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5764,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5765,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5766,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5767,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5768,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5769,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5770,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5771,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5772,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 5773,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5774,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5775,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5776,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5777,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 5778,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5779,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Blue"
    },
    {
        "ID": 5780,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5781,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5782,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Gray"
    },
    {
        "ID": 5783,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5784,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 5785,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5786,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5787,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5788,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5789,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5790,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5791,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5792,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5793,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5794,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5795,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5796,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5797,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5798,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5799,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5800,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5801,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5802,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5803,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5804,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5805,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5806,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5807,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5808,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Pink"
    },
    {
        "ID": 5809,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5810,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5811,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5812,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5813,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5814,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5815,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Blue"
    },
    {
        "ID": 5816,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5817,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5818,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5819,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5820,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5821,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5822,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5823,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5824,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5825,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5826,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5827,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5828,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5829,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5830,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5831,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 5832,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5833,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5834,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5835,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5836,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5837,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5838,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5839,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5840,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5841,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5842,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5843,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5844,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5845,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5846,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5847,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5848,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5849,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5850,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5851,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5852,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5853,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5854,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5855,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5856,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5857,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5858,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 5859,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5860,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5861,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5862,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5863,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5864,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5865,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5866,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5867,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5868,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5869,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5870,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5871,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5872,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5873,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5874,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5875,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5876,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5877,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 5878,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5879,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5880,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5881,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5882,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5883,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5884,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5885,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5886,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5887,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5888,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5889,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5890,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5891,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5892,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5893,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5894,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5895,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5896,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5897,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5898,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5899,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 5900,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5901,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5902,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5903,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5904,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5905,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5906,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5907,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5908,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5909,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5910,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5911,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5912,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5913,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5914,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5915,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5916,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5917,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5918,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5919,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5920,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5921,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5922,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5923,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5924,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5925,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5926,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Pink"
    },
    {
        "ID": 5927,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5928,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5929,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5930,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5931,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5932,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5933,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5934,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5935,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 5936,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5937,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5938,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5939,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5940,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5941,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5942,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5943,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5944,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5945,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5946,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5947,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5948,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5949,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5950,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5951,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5952,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5953,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5954,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5955,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5956,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5957,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5958,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 5959,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5960,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5961,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5962,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5963,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 5964,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5965,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5966,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5967,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5968,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5969,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 5970,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5971,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5972,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5973,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5974,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5975,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5976,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 5977,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5978,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5979,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5980,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5981,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5982,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 5983,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 5984,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 5985,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 5986,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5987,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5988,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5989,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5990,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 5991,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 5992,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5993,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 5994,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5995,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 5996,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 5997,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 5998,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 5999,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6000,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6001,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6002,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6003,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6004,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6005,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6006,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Blue"
    },
    {
        "ID": 6007,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6008,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6009,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6010,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6011,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6012,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6013,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6014,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 6015,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6016,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6017,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6018,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6019,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6020,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6021,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6022,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6023,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6024,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6025,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6026,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6027,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6028,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6029,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6030,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6031,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6032,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6033,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6034,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6035,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6036,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6037,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6038,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6039,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6040,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6041,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6042,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6043,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6044,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6045,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6046,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Purple"
    },
    {
        "ID": 6047,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6048,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6049,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6050,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6051,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6052,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6053,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6054,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6055,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6056,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6057,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6058,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6059,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6060,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6061,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6062,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6063,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6064,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6065,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6066,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6067,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6068,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6069,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6070,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6071,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6072,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6073,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6074,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6075,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6076,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Pink"
    },
    {
        "ID": 6077,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6078,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6079,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6080,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6081,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 6082,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6083,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6084,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6085,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6086,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6087,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6088,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6089,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6090,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6091,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6092,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6093,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6094,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6095,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6096,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6097,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 6098,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6099,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6100,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6101,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6102,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6103,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6104,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6105,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6106,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6107,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6108,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6109,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6110,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6111,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6112,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6113,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6114,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6115,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6116,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6117,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6118,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6119,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6120,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6121,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6122,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6123,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6124,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6125,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6126,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6127,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6128,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6129,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6130,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6131,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Pitchfork",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6132,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6133,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6134,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6135,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6136,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6137,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6138,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6139,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6140,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6141,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6142,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6143,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6144,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6145,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6146,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6147,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6148,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6149,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6150,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6151,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 6152,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6153,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 6154,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6155,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6156,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6157,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6158,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 6159,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6160,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6161,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6162,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6163,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6164,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6165,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6166,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6167,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6168,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6169,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6170,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6171,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6172,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6173,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6174,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6175,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6176,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6177,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6178,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6179,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6180,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6181,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6182,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6183,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6184,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6185,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6186,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6187,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6188,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6189,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 6190,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6191,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6192,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 6193,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6194,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6195,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6196,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6197,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6198,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6199,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6200,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6201,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6202,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6203,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6204,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6205,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6206,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6207,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6208,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6209,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6210,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6211,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6212,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6213,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6214,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 6215,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6216,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6217,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6218,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6219,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6220,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 6221,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6222,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6223,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6224,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6225,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6226,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6227,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6228,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6229,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6230,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6231,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6232,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6233,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6234,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6235,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6236,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6237,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6238,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6239,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6240,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6241,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6242,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6243,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6244,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6245,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6246,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6247,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6248,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6249,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6250,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6251,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6252,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6253,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6254,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6255,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6256,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6257,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6258,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6259,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6260,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6261,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6262,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6263,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6264,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6265,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6266,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6267,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6268,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6269,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6270,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6271,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6272,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6273,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6274,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6275,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6276,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6277,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6278,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6279,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6280,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6281,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6282,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 6283,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6284,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6285,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6286,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6287,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6288,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6289,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6290,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6291,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6292,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6293,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6294,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6295,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6296,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6297,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 6298,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6299,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6300,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 6301,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6302,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6303,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6304,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6305,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6306,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6307,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6308,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6309,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6310,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6311,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6312,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6313,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6314,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6315,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6316,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6317,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6318,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6319,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6320,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 6321,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6322,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6323,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6324,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6325,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6326,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6327,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6328,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6329,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6330,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Pink"
    },
    {
        "ID": 6331,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6332,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6333,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6334,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6335,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6336,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6337,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6338,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6339,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6340,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6341,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6342,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6343,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6344,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6345,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6346,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6347,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6348,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 6349,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6350,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6351,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6352,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6353,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6354,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6355,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6356,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6357,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6358,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6359,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6360,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6361,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6362,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6363,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6364,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6365,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6366,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6367,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6368,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6369,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6370,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6371,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6372,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6373,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6374,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6375,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6376,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6377,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6378,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6379,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6380,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6381,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6382,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6383,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6384,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6385,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6386,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6387,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6388,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6389,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 6390,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6391,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6392,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6393,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6394,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6395,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6396,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6397,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6398,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6399,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6400,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6401,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6402,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6403,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6404,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6405,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6406,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6407,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6408,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6409,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6410,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6411,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6412,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6413,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6414,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6415,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6416,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6417,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6418,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6419,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6420,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6421,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6422,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6423,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6424,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6425,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6426,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6427,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6428,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6429,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6430,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6431,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6432,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Yellow"
    },
    {
        "ID": 6433,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6434,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6435,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6436,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6437,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6438,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6439,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6440,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6441,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6442,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6443,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Red Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6444,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6445,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6446,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6447,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6448,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6449,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6450,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 6451,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6452,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6453,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6454,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6455,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6456,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 6457,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6458,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6459,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6460,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6461,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6462,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6463,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6464,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6465,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6466,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red Wine"
    },
    {
        "ID": 6467,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6468,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6469,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6470,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6471,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6472,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6473,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6474,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6475,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6476,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6477,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6478,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6479,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6480,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6481,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6482,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6483,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6484,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6485,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6486,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6487,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6488,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6489,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6490,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6491,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6492,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 6493,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 6494,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6495,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6496,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6497,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6498,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6499,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6500,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6501,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6502,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6503,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6504,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6505,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6506,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6507,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6508,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6509,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6510,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6511,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6512,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6513,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6514,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6515,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6516,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6517,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6518,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6519,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6520,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6521,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6522,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6523,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6524,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6525,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 6526,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6527,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6528,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6529,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6530,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6531,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6532,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6533,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6534,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6535,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6536,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6537,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6538,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6539,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6540,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6541,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6542,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6543,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6544,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6545,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6546,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6547,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6548,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6549,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6550,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6551,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6552,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6553,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6554,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6555,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6556,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6557,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6558,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 6559,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6560,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6561,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6562,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6563,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6564,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6565,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6566,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6567,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6568,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6569,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6570,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6571,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Blue"
    },
    {
        "ID": 6572,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6573,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6574,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6575,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6576,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6577,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6578,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6579,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6580,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6581,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6582,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6583,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6584,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6585,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6586,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6587,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6588,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6589,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6590,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6591,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6592,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6593,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 6594,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6595,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6596,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6597,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6598,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6599,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6600,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6601,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6602,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6603,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6604,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6605,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6606,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6607,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6608,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6609,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6610,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6611,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6612,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6613,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6614,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6615,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6616,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6617,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6618,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6619,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6620,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6621,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6622,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6623,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6624,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6625,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6626,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6627,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6628,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6629,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6630,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Light Gray"
    },
    {
        "ID": 6631,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6632,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6633,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6634,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6635,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6636,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6637,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6638,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6639,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6640,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 6641,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6642,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6643,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6644,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6645,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6646,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6647,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6648,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6649,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6650,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6651,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6652,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6653,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6654,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6655,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6656,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6657,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6658,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6659,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6660,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6661,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6662,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6663,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6664,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Pitchfork",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6665,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6666,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6667,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6668,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6669,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6670,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6671,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6672,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6673,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6674,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Gray"
    },
    {
        "ID": 6675,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 6676,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6677,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6678,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6679,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6680,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6681,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6682,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6683,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6684,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6685,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6686,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6687,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6688,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6689,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6690,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6691,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6692,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6693,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6694,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6695,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6696,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6697,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Pink TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6698,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6699,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6700,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6701,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 6702,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6703,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6704,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6705,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6706,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6707,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6708,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6709,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6710,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6711,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6712,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6713,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6714,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6715,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6716,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6717,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6718,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6719,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6720,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 6721,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6722,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6723,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6724,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6725,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6726,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6727,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6728,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6729,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6730,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6731,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6732,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6733,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6734,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6735,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6736,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6737,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6738,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6739,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6740,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6741,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Pink"
    },
    {
        "ID": 6742,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Pink"
    },
    {
        "ID": 6743,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6744,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6745,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6746,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6747,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6748,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6749,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6750,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6751,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 6752,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6753,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6754,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6755,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6756,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6757,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6758,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6759,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6760,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6761,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6762,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6763,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6764,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6765,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6766,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6767,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6768,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6769,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6770,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6771,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6772,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6773,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6774,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6775,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6776,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6777,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6778,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6779,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6780,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6781,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6782,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6783,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6784,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6785,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6786,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6787,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6788,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6789,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6790,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6791,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6792,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6793,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6794,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6795,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6796,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6797,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6798,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6799,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6800,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6801,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6802,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6803,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6804,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6805,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6806,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6807,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6808,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6809,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6810,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6811,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6812,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6813,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6814,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6815,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6816,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6817,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6818,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6819,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6820,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6821,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6822,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red Wine"
    },
    {
        "ID": 6823,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6824,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6825,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6826,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 6827,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6828,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6829,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6830,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6831,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6832,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6833,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6834,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6835,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6836,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6837,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6838,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Red Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6839,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6840,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6841,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6842,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6843,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6844,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6845,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6846,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6847,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6848,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6849,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6850,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6851,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6852,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6853,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6854,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6855,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6856,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6857,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6858,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6859,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6860,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6861,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6862,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6863,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6864,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6865,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 6866,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6867,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6868,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6869,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6870,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6871,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6872,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6873,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6874,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6875,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6876,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6877,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6878,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6879,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6880,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6881,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6882,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6883,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6884,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6885,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6886,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6887,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6888,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6889,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6890,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6891,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6892,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6893,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6894,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6895,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6896,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6897,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6898,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6899,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6900,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6901,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6902,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6903,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6904,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6905,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6906,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6907,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6908,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6909,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6910,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6911,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6912,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6913,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6914,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 6915,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6916,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6917,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6918,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6919,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6920,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6921,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6922,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6923,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6924,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6925,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6926,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6927,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6928,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6929,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6930,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick",
        "Eyes": "",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6931,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 6932,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6933,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6934,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6935,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 6936,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6937,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6938,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6939,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6940,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6941,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6942,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6943,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6944,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6945,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Red TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6946,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6947,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6948,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6949,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6950,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6951,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6952,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 6953,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6954,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6955,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6956,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6957,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6958,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6959,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6960,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6961,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6962,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6963,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6964,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6965,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6966,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6967,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 6968,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6969,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6970,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6971,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6972,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 6973,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6974,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6975,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 6976,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6977,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 6978,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6979,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6980,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6981,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 6982,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6983,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6984,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 6985,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6986,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6987,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 6988,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 6989,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 6990,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6991,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6992,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 6993,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 6994,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 6995,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6996,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 6997,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 6998,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 6999,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7000,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7001,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7002,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7003,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7004,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7005,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7006,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7007,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7008,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7009,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7010,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7011,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7012,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7013,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7014,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7015,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7016,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7017,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 7018,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7019,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7020,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7021,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7022,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7023,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7024,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7025,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7026,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7027,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7028,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7029,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7030,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7031,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7032,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7033,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7034,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7035,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7036,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7037,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7038,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7039,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7040,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7041,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7042,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7043,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Blue"
    },
    {
        "ID": 7044,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7045,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7046,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7047,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7048,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7049,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7050,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7051,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7052,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7053,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7054,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7055,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7056,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7057,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7058,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7059,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7060,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7061,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7062,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7063,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7064,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7065,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7066,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7067,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7068,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7069,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7070,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7071,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7072,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7073,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7074,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7075,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7076,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7077,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7078,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7079,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7080,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7081,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7082,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7083,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7084,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7085,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7086,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7087,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7088,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7089,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7090,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7091,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7092,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7093,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7094,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7095,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7096,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7097,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7098,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7099,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7100,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7101,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7102,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7103,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7104,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7105,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7106,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7107,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7108,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7109,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7110,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7111,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7112,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7113,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7114,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7115,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7116,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7117,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7118,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7119,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7120,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7121,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7122,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7123,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7124,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7125,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7126,
        "Character": "Rex",
        "Body Color": "Red Devil",
        "Speech Bubble": "",
        "Right Arm": "Red Devil Pitchfork",
        "Headwear": "Red Devil Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Red Devil Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Red Devil Heavy Metal",
        "Right Leg": "Red Devil Skate",
        "Left Leg": "Red Devil Open Skate",
        "Celestial Attribute": "Red Devil Tail",
        "Background": "Orange Special"
    },
    {
        "ID": 7127,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7128,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7129,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7130,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7131,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7132,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7133,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7134,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7135,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7136,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7137,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7138,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7139,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7140,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7141,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7142,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7143,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7144,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7145,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7146,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7147,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7148,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7149,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7150,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7151,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7152,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7153,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7154,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7155,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7156,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7157,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7158,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7159,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7160,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7161,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7162,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7163,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7164,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7165,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7166,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7167,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7168,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7169,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7170,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7171,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7172,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7173,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 7174,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7175,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7176,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7177,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7178,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7179,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7180,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7181,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7182,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7183,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7184,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7185,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7186,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7187,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7188,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7189,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7190,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7191,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7192,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7193,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7194,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7195,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 7196,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7197,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7198,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7199,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7200,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7201,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7202,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7203,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7204,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7205,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7206,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7207,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7208,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7209,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7210,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7211,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7212,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7213,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 7214,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7215,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7216,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7217,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7218,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7219,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7220,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7221,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7222,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7223,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7224,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7225,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7226,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7227,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7228,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Money Bag",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7229,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7230,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7231,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7232,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Pink"
    },
    {
        "ID": 7233,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7234,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7235,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7236,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7237,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7238,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red"
    },
    {
        "ID": 7239,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7240,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7241,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7242,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7243,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7244,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7245,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7246,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7247,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7248,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7249,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7250,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7251,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7252,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7253,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7254,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7255,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7256,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7257,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7258,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7259,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7260,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7261,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7262,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7263,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7264,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7265,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7266,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7267,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7268,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7269,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7270,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7271,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7272,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7273,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7274,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7275,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7276,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7277,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7278,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7279,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7280,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7281,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7282,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7283,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7284,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7285,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7286,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7287,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7288,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7289,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7290,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7291,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7292,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7293,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7294,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7295,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7296,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7297,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7298,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7299,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7300,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7301,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7302,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7303,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7304,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7305,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7306,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7307,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7308,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7309,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7310,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7311,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7312,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7313,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7314,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7315,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7316,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7317,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7318,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7319,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7320,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7321,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7322,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7323,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7324,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7325,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7326,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7327,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7328,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7329,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7330,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7331,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7332,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7333,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7334,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7335,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7336,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7337,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7338,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7339,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7340,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7341,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7342,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7343,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7344,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 7345,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7346,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7347,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7348,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7349,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7350,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7351,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7352,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7353,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7354,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7355,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7356,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7357,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7358,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7359,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7360,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7361,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7362,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7363,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7364,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7365,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7366,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 7367,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7368,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7369,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7370,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7371,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7372,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7373,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7374,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7375,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7376,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7377,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7378,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7379,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7380,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7381,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7382,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7383,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7384,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7385,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7386,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7387,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7388,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7389,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7390,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7391,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7392,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7393,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7394,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7395,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7396,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7397,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7398,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7399,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7400,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7401,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7402,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 7403,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7404,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7405,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7406,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 7407,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7408,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7409,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7410,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7411,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7412,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7413,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7414,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7415,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7416,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7417,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7418,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7419,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7420,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7421,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7422,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7423,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7424,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7425,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7426,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7427,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7428,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7429,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7430,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7431,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7432,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7433,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7434,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7435,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7436,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7437,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7438,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7439,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7440,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7441,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7442,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7443,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7444,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7445,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7446,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7447,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7448,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7449,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7450,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7451,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7452,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7453,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7454,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7455,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7456,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7457,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7458,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7459,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7460,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7461,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7462,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7463,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7464,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7465,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7466,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7467,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7468,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7469,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7470,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7471,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7472,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7473,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7474,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7475,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7476,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7477,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7478,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7479,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7480,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7481,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7482,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7483,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7484,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7485,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7486,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7487,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7488,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7489,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7490,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7491,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7492,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7493,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 7494,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7495,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7496,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7497,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7498,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7499,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7500,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7501,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7502,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7503,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7504,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7505,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7506,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7507,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7508,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7509,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7510,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7511,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7512,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 7513,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7514,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7515,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7516,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7517,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7518,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7519,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 7520,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red"
    },
    {
        "ID": 7521,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7522,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7523,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7524,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7525,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red"
    },
    {
        "ID": 7526,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7527,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7528,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7529,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7530,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7531,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7532,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7533,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7534,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7535,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7536,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7537,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7538,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7539,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7540,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7541,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7542,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7543,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7544,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7545,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7546,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7547,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 7548,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7549,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7550,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7551,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7552,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7553,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7554,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7555,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7556,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7557,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7558,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7559,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7560,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Red Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7561,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7562,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7563,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7564,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7565,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7566,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7567,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7568,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7569,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7570,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7571,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7572,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7573,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7574,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7575,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7576,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7577,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7578,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7579,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7580,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7581,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7582,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7583,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7584,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7585,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7586,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7587,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7588,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7589,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7590,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7591,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7592,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7593,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7594,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7595,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7596,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7597,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7598,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7599,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7600,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7601,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7602,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7603,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7604,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7605,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7606,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7607,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7608,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7609,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7610,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7611,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7612,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7613,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7614,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7615,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7616,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7617,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7618,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 7619,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7620,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7621,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7622,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7623,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7624,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 7625,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7626,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7627,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 7628,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7629,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red"
    },
    {
        "ID": 7630,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7631,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7632,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7633,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7634,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7635,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7636,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red Wine"
    },
    {
        "ID": 7637,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 7638,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7639,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7640,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7641,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7642,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7643,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7644,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7645,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7646,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 7647,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7648,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7649,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7650,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7651,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7652,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7653,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7654,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7655,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7656,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7657,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7658,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7659,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7660,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7661,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7662,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7663,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7664,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7665,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7666,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7667,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7668,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7669,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7670,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7671,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7672,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7673,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7674,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7675,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7676,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7677,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7678,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7679,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7680,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7681,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7682,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red"
    },
    {
        "ID": 7683,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7684,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7685,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7686,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7687,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7688,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7689,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7690,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7691,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 7692,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7693,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7694,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7695,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7696,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7697,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7698,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7699,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7700,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7701,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7702,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7703,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7704,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7705,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7706,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7707,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7708,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7709,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7710,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7711,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7712,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7713,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7714,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7715,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7716,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7717,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7718,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7719,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7720,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7721,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7722,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7723,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7724,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 7725,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7726,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7727,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7728,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7729,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7730,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7731,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7732,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7733,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7734,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7735,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 7736,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7737,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 7738,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7739,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7740,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7741,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7742,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7743,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 7744,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7745,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7746,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7747,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7748,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7749,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7750,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7751,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7752,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7753,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7754,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7755,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7756,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7757,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7758,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7759,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7760,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7761,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7762,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7763,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7764,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7765,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 7766,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7767,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7768,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7769,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7770,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7771,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7772,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7773,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7774,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7775,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7776,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red Wine"
    },
    {
        "ID": 7777,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7778,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7779,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7780,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7781,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7782,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 7783,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7784,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7785,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7786,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7787,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7788,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7789,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7790,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7791,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7792,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7793,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7794,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7795,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7796,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7797,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7798,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7799,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7800,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7801,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7802,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 7803,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7804,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7805,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7806,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7807,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7808,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7809,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7810,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7811,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7812,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7813,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7814,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7815,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7816,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7817,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7818,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7819,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7820,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7821,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7822,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7823,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7824,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7825,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7826,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7827,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7828,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7829,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7830,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Red Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7831,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7832,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7833,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7834,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7835,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7836,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7837,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7838,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7839,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Gray"
    },
    {
        "ID": 7840,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7841,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7842,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7843,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7844,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7845,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7846,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7847,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7848,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7849,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7850,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7851,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7852,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 7853,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7854,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7855,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7856,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7857,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7858,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 7859,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7860,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7861,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7862,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7863,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7864,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7865,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7866,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 7867,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7868,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7869,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7870,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7871,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7872,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7873,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 7874,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 7875,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7876,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7877,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7878,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7879,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7880,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7881,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7882,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red"
    },
    {
        "ID": 7883,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7884,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7885,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7886,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7887,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7888,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7889,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Red Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7890,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7891,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7892,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7893,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7894,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7895,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7896,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7897,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7898,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7899,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7900,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7901,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7902,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7903,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7904,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7905,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7906,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7907,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7908,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7909,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7910,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7911,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7912,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7913,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7914,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7915,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7916,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7917,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7918,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7919,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7920,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7921,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7922,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7923,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7924,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7925,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Purple"
    },
    {
        "ID": 7926,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7927,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7928,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7929,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 7930,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7931,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 7932,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7933,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7934,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7935,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7936,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7937,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7938,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7939,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7940,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7941,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7942,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7943,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7944,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7945,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7946,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7947,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7948,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7949,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7950,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7951,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7952,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7953,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7954,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7955,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 7956,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7957,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7958,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7959,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7960,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7961,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7962,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7963,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7964,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7965,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 7966,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7967,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 7968,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7969,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7970,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7971,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7972,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7973,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7974,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7975,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7976,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7977,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 7978,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 7979,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 7980,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 7981,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7982,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7983,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7984,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7985,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 7986,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 7987,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 7988,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7989,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 7990,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 7991,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7992,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 7993,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 7994,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 7995,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 7996,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 7997,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 7998,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 7999,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8000,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8001,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8002,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8003,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8004,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8005,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8006,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8007,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8008,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8009,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8010,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8011,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8012,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8013,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8014,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8015,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8016,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8017,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8018,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8019,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8020,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 8021,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8022,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8023,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8024,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8025,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8026,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8027,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8028,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8029,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick",
        "Eyes": "",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8030,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8031,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8032,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8033,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8034,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8035,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8036,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8037,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8038,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8039,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8040,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8041,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8042,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8043,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8044,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8045,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8046,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8047,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8048,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8049,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8050,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8051,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8052,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8053,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8054,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8055,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8056,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8057,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8058,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8059,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8060,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8061,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8062,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8063,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8064,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8065,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8066,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8067,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 8068,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8069,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8070,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8071,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8072,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 8073,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8074,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8075,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8076,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8077,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8078,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8079,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8080,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8081,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8082,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8083,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8084,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8085,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8086,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8087,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8088,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8089,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8090,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8091,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8092,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8093,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8094,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8095,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8096,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8097,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8098,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8099,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8100,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8101,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8102,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8103,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8104,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8105,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8106,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8107,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 8108,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8109,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8110,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8111,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8112,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8113,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8114,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8115,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8116,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8117,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8118,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8119,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8120,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8121,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8122,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8123,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8124,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8125,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8126,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8127,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8128,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8129,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8130,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8131,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8132,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8133,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8134,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8135,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8136,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8137,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 8138,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8139,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8140,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8141,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8142,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8143,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 8144,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8145,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8146,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8147,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8148,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8149,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8150,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8151,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8152,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8153,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8154,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8155,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8156,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8157,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8158,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8159,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8160,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8161,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8162,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8163,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8164,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8165,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8166,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8167,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8168,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8169,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8170,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8171,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 8172,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8173,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8174,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8175,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8176,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8177,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8178,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8179,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8180,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8181,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8182,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8183,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8184,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8185,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8186,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8187,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8188,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8189,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8190,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8191,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8192,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8193,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8194,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8195,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8196,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 8197,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8198,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8199,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8200,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8201,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8202,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8203,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Gray"
    },
    {
        "ID": 8204,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8205,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8206,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8207,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8208,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8209,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8210,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8211,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8212,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8213,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8214,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 8215,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8216,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8217,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8218,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8219,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8220,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8221,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8222,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8223,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8224,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8225,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8226,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8227,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8228,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8229,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8230,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8231,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8232,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8233,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8234,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8235,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8236,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8237,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8238,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8239,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8240,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8241,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8242,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Purple"
    },
    {
        "ID": 8243,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8244,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8245,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8246,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8247,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8248,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8249,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8250,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8251,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8252,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8253,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8254,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8255,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8256,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8257,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8258,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8259,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8260,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8261,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8262,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8263,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8264,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8265,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8266,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8267,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8268,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8269,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8270,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8271,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8272,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8273,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8274,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8275,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8276,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8277,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8278,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8279,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8280,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8281,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8282,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8283,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8284,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8285,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8286,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8287,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8288,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8289,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8290,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8291,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8292,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8293,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8294,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8295,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8296,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8297,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8298,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8299,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8300,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8301,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 8302,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8303,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8304,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8305,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8306,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8307,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 8308,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8309,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8310,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8311,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8312,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8313,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8314,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8315,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8316,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8317,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8318,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8319,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8320,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8321,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8322,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8323,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8324,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8325,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8326,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8327,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8328,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Purple"
    },
    {
        "ID": 8329,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8330,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8331,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8332,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8333,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8334,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8335,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8336,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8337,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8338,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8339,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8340,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8341,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8342,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8343,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8344,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8345,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8346,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8347,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8348,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8349,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8350,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8351,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8352,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8353,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8354,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8355,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8356,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8357,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8358,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8359,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8360,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8361,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 8362,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8363,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8364,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8365,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8366,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Razor",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8367,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8368,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8369,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8370,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8371,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8372,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8373,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8374,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8375,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8376,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8377,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8378,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8379,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8380,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8381,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8382,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8383,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8384,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8385,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8386,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8387,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8388,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8389,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8390,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8391,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8392,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8393,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8394,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8395,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8396,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8397,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8398,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 8399,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8400,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8401,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8402,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8403,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8404,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8405,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8406,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8407,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8408,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8409,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8410,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8411,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8412,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8413,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8414,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8415,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8416,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8417,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 8418,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 8419,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8420,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8421,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8422,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8423,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8424,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8425,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8426,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 8427,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8428,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8429,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8430,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8431,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8432,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8433,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8434,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8435,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8436,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8437,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8438,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8439,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8440,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8441,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8442,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8443,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8444,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8445,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8446,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8447,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8448,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8449,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 8450,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8451,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8452,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8453,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8454,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8455,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8456,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8457,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8458,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8459,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 8460,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8461,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8462,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8463,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8464,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8465,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8466,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8467,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8468,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8469,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8470,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8471,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8472,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8473,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8474,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8475,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8476,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8477,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8478,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Pink TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8479,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8480,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8481,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8482,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8483,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8484,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8485,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8486,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8487,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8488,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8489,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8490,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8491,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8492,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8493,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8494,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8495,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8496,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8497,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8498,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8499,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8500,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8501,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8502,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8503,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8504,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8505,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8506,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8507,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 8508,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8509,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8510,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8511,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8512,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8513,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8514,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8515,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8516,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8517,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8518,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Blue"
    },
    {
        "ID": 8519,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8520,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8521,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8522,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8523,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8524,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8525,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8526,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8527,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8528,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8529,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8530,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8531,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8532,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8533,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8534,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8535,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8536,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8537,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8538,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8539,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8540,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8541,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8542,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8543,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8544,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8545,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8546,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8547,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8548,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8549,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8550,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8551,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8552,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Red Skate Crossed",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 8553,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8554,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8555,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8556,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8557,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8558,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8559,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 8560,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8561,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8562,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8563,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8564,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8565,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8566,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8567,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Blue Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 8568,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Red Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8569,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8570,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8571,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8572,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8573,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8574,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8575,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8576,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8577,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8578,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8579,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8580,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8581,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 8582,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8583,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8584,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8585,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8586,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8587,
        "Character": "Syrin",
        "Body Color": "Gold",
        "Speech Bubble": "",
        "Right Arm": "Gold Finger",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Gold Fist",
        "Right Leg": "Gold Skate",
        "Left Leg": "Gold Crossed Skate",
        "Celestial Attribute": "Gold Wings",
        "Background": "Dark Gray Special"
    },
    {
        "ID": 8588,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8589,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8590,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8591,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8592,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8593,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8594,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8595,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8596,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8597,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8598,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8599,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 8600,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8601,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8602,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8603,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8604,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8605,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8606,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8607,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8608,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8609,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8610,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8611,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8612,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Red Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8613,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Red Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8614,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8615,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8616,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8617,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Purple"
    },
    {
        "ID": 8618,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8619,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8620,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Purple"
    },
    {
        "ID": 8621,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8622,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8623,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8624,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8625,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8626,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8627,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8628,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8629,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8630,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8631,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8632,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8633,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Gray"
    },
    {
        "ID": 8634,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8635,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8636,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8637,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8638,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8639,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8640,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8641,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8642,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8643,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8644,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 8645,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8646,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Pitchfork",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8647,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8648,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8649,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8650,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8651,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8652,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8653,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8654,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8655,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8656,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8657,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8658,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8659,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8660,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8661,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8662,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8663,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8664,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8665,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8666,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Orange"
    },
    {
        "ID": 8667,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8668,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8669,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8670,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8671,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8672,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8673,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8674,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8675,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8676,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8677,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8678,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8679,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8680,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8681,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8682,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8683,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8684,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8685,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8686,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8687,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8688,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8689,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8690,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 8691,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8692,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8693,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8694,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8695,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8696,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8697,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8698,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8699,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8700,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8701,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8702,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 8703,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8704,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8705,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8706,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8707,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8708,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8709,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8710,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8711,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8712,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8713,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8714,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Gray"
    },
    {
        "ID": 8715,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8716,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Purple"
    },
    {
        "ID": 8717,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8718,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8719,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8720,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8721,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8722,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8723,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8724,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8725,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Yellow"
    },
    {
        "ID": 8726,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8727,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8728,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8729,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8730,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8731,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8732,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8733,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8734,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8735,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8736,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8737,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8738,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8739,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8740,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8741,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8742,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8743,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8744,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8745,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8746,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8747,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8748,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8749,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8750,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8751,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8752,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8753,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8754,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8755,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8756,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8757,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8758,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8759,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8760,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8761,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8762,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8763,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Pink"
    },
    {
        "ID": 8764,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8765,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 8766,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8767,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8768,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8769,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8770,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 8771,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8772,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8773,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8774,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 8775,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8776,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8777,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8778,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8779,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8780,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 8781,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8782,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8783,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8784,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8785,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8786,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8787,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8788,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8789,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8790,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8791,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8792,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8793,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8794,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8795,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8796,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8797,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8798,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8799,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8800,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8801,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8802,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8803,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Purple"
    },
    {
        "ID": 8804,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8805,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Red Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8806,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8807,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8808,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8809,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8810,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8811,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8812,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8813,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8814,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8815,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8816,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8817,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8818,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Blue"
    },
    {
        "ID": 8819,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8820,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8821,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8822,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8823,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8824,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8825,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8826,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8827,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8828,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8829,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8830,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8831,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8832,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8833,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8834,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8835,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8836,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8837,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8838,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8839,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8840,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8841,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8842,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8843,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8844,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8845,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8846,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8847,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8848,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8849,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8850,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8851,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8852,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8853,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 8854,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8855,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8856,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8857,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8858,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8859,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8860,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Pitchfork",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8861,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8862,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8863,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 8864,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8865,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8866,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8867,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8868,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8869,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8870,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8871,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8872,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8873,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8874,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8875,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8876,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8877,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8878,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8879,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8880,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8881,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8882,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Blue Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8883,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8884,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8885,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8886,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8887,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8888,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8889,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Red Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8890,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8891,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8892,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8893,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8894,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8895,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8896,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8897,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8898,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8899,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8900,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8901,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8902,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8903,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8904,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8905,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 8906,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8907,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8908,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 8909,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8910,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8911,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8912,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8913,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8914,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8915,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8916,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8917,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8918,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8919,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8920,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8921,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8922,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8923,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8924,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8925,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8926,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8927,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8928,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8929,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8930,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8931,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8932,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8933,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8934,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8935,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8936,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8937,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8938,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8939,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8940,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8941,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8942,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8943,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8944,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8945,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8946,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8947,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8948,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8949,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8950,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8951,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8952,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8953,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8954,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8955,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8956,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8957,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8958,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8959,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8960,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8961,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 8962,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8963,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8964,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8965,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Blue Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 8966,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8967,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8968,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8969,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8970,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8971,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8972,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8973,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8974,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 8975,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8976,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8977,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Green"
    },
    {
        "ID": 8978,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8979,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8980,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8981,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 8982,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 8983,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 8984,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8985,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 8986,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8987,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8988,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8989,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 8990,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 8991,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8992,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 8993,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 8994,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8995,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 8996,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8997,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 8998,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 8999,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9000,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9001,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9002,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9003,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9004,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9005,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9006,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9007,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9008,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9009,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9010,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9011,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9012,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9013,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9014,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9015,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9016,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9017,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9018,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9019,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9020,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9021,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9022,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9023,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9024,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9025,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9026,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9027,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9028,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9029,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9030,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9031,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 9032,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9033,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9034,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9035,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9036,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9037,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9038,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9039,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9040,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9041,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9042,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9043,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9044,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9045,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9046,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9047,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9048,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9049,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9050,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9051,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9052,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9053,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9054,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9055,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9056,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9057,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9058,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9059,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9060,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9061,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9062,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9063,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9064,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 9065,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9066,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9067,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Red TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9068,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9069,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Pink"
    },
    {
        "ID": 9070,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 9071,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9072,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9073,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9074,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9075,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9076,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9077,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9078,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9079,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9080,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9081,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9082,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9083,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9084,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9085,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9086,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9087,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9088,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9089,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9090,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9091,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9092,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9093,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9094,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9095,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9096,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9097,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9098,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9099,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9100,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9101,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9102,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9103,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9104,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9105,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9106,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9107,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9108,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9109,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9110,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9111,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9112,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9113,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9114,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9115,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9116,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Money Bag",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9117,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9118,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9119,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9120,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Red Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9121,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9122,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9123,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9124,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9125,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9126,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9127,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9128,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9129,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9130,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9131,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9132,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9133,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9134,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9135,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9136,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9137,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9138,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9139,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9140,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9141,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9142,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9143,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9144,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9145,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9146,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9147,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9148,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9149,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9150,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9151,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9152,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9153,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 9154,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9155,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Purple"
    },
    {
        "ID": 9156,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9157,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9158,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 9159,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9160,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Desert"
    },
    {
        "ID": 9161,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9162,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9163,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9164,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9165,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9166,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9167,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 9168,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9169,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9170,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9171,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9172,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9173,
        "Character": "Rex",
        "Body Color": "Murdered Out",
        "Speech Bubble": "",
        "Right Arm": "Murdered Out Spiked Bracelet",
        "Headwear": "Murdered Out Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "More War Sign Murdered Out",
        "Right Leg": "Murdered Out Bomb",
        "Left Leg": "Murdered Out Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue Special"
    },
    {
        "ID": 9174,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9175,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9176,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9177,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9178,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9179,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9180,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9181,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9182,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9183,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9184,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9185,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 9186,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9187,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9188,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9189,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9190,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9191,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9192,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9193,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 9194,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9195,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9196,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9197,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9198,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9199,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9200,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9201,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9202,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9203,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Rx Pattern Knapsack",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9204,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9205,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9206,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9207,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9208,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9209,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9210,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9211,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9212,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9213,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9214,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9215,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9216,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9217,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9218,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9219,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9220,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9221,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9222,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9223,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9224,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 9225,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9226,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9227,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9228,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9229,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9230,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9231,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9232,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9233,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9234,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9235,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9236,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9237,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9238,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9239,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9240,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9241,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9242,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9243,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9244,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9245,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9246,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9247,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Pink"
    },
    {
        "ID": 9248,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9249,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9250,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9251,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9252,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9253,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9254,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9255,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9256,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9257,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9258,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9259,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9260,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9261,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9262,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9263,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9264,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9265,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9266,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9267,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9268,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9269,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9270,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9271,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9272,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9273,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9274,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9275,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9276,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9277,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9278,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9279,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9280,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9281,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9282,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9283,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9284,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9285,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red"
    },
    {
        "ID": 9286,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Orange"
    },
    {
        "ID": 9287,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9288,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9289,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9290,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9291,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9292,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9293,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9294,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9295,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9296,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Black Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9297,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Red Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9298,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9299,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9300,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9301,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9302,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9303,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9304,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9305,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9306,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9307,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9308,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9309,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9310,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9311,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9312,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9313,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9314,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9315,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Leathered Out Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Happy",
        "Left Arm": "Whip",
        "Right Leg": "Standing Leathered Out",
        "Left Leg": "Standing Catwoman Leathered Out",
        "Celestial Attribute": "",
        "Background": "Dark Blue Special"
    },
    {
        "ID": 9316,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9317,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9318,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9319,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9320,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9321,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9322,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9323,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9324,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9325,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9326,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9327,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9328,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9329,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9330,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9331,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9332,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9333,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9334,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9335,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9336,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9337,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9338,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9339,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9340,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9341,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9342,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9343,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9344,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9345,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9346,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9347,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9348,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9349,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9350,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9351,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9352,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9353,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9354,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9355,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9356,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9357,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9358,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9359,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9360,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9361,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9362,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9363,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9364,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9365,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9366,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9367,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9368,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Special Gray Skate",
        "Left Leg": "Special Gray Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9369,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9370,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9371,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9372,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9373,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9374,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Cat Mask",
        "Mouth": "Lipstick",
        "Eyes": "",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9375,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9376,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9377,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9378,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9379,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9380,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9381,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9382,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9383,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9384,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9385,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9386,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9387,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9388,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9389,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9390,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9391,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9392,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9393,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9394,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9395,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9396,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9397,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9398,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9399,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9400,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9401,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9402,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9403,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9404,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9405,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9406,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9407,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9408,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9409,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9410,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9411,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9412,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9413,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9414,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9415,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9416,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9417,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9418,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9419,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Red"
    },
    {
        "ID": 9420,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9421,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9422,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9423,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9424,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9425,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9426,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9427,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9428,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9429,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 9430,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9431,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9432,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9433,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9434,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9435,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9436,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9437,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9438,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9439,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9440,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9441,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9442,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9443,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "White Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9444,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9445,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9446,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9447,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Pink"
    },
    {
        "ID": 9448,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9449,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9450,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9451,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9452,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9453,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Light Gray"
    },
    {
        "ID": 9454,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9455,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9456,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9457,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9458,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9459,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9460,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9461,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9462,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "Pink Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 9463,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9464,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9465,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9466,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9467,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9468,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9469,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9470,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9471,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9472,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9473,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9474,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Pink"
    },
    {
        "ID": 9475,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9476,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9477,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9478,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9479,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9480,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9481,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9482,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9483,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9484,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9485,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9486,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9487,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9488,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9489,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9490,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9491,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Peace",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Green Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9492,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9493,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9494,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "Wings",
        "Background": "Dark Purple"
    },
    {
        "ID": 9495,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9496,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9497,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9498,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9499,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9500,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9501,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9502,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9503,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9504,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9505,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9506,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9507,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9508,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9509,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9510,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9511,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9512,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9513,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9514,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9515,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9516,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9517,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9518,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9519,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9520,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9521,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9522,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9523,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9524,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9525,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9526,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9527,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9528,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9529,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9530,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9531,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9532,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9533,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9534,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9535,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9536,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Yellow Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9537,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9538,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9539,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9540,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9541,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9542,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9543,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9544,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 9545,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9546,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9547,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Blue"
    },
    {
        "ID": 9548,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Peace",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9549,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9550,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9551,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9552,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9553,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9554,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9555,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9556,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9557,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9558,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Pitchfork",
        "Headwear": "Green Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "",
        "Left Leg": "Underwear",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9559,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9560,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9561,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9562,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9563,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9564,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9565,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9566,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9567,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9568,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9569,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9570,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9571,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9572,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9573,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 9574,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9575,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9576,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9577,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9578,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9579,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9580,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9581,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9582,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9583,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9584,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Ruby Slipper with Blue Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9585,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9586,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9587,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Razor",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9588,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9589,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9590,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9591,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9592,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9593,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 9594,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 9595,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9596,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9597,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9598,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9599,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9600,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9601,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9602,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9603,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9604,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9605,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9606,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9607,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9608,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 9609,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9610,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9611,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9612,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Black Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9613,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9614,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9615,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9616,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9617,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9618,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9619,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Razor",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9620,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9621,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9622,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9623,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9624,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red"
    },
    {
        "ID": 9625,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9626,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Candle",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9627,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9628,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9629,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9630,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9631,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9632,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9633,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9634,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 9635,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9636,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9637,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9638,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "Wings",
        "Background": "Pink"
    },
    {
        "ID": 9639,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9640,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9641,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9642,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9643,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9644,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 9645,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9646,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9647,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9648,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9649,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9650,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9651,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9652,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9653,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9654,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9655,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9656,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9657,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9658,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9659,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9660,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9661,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9662,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9663,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9664,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9665,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9666,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9667,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9668,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9669,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9670,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9671,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red"
    },
    {
        "ID": 9672,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9673,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9674,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9675,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9676,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9677,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9678,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9679,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9680,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9681,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9682,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9683,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9684,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9685,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9686,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9687,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9688,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9689,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Green One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9690,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9691,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9692,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9693,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9694,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9695,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9696,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9697,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9698,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9699,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9700,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9701,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9702,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9703,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Green Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9704,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9705,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9706,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Fist",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9707,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Blue"
    },
    {
        "ID": 9708,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9709,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9710,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9711,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Green"
    },
    {
        "ID": 9712,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Smoking",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9713,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9714,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9715,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9716,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9717,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Two Tone Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9718,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Light Purple"
    },
    {
        "ID": 9719,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9720,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9721,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9722,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9723,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9724,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Peace",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9725,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White One Sock",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9726,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9727,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9728,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9729,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "White Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9730,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9731,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9732,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9733,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9734,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9735,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9736,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9737,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9738,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Pink Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9739,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9740,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Green Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9741,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9742,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9743,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9744,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9745,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9746,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9747,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9748,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9749,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9750,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9751,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9752,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9753,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Peace",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "Wings",
        "Background": "Red Wine"
    },
    {
        "ID": 9754,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "White Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9755,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9756,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9757,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9758,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9759,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9760,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9761,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9762,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9763,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9764,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9765,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9766,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9767,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9768,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Bandage",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9769,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9770,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9771,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9772,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9773,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9774,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9775,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9776,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9777,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9778,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9779,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9780,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9781,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9782,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9783,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9784,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9785,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9786,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9787,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9788,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9789,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9790,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9791,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9792,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9793,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9794,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9795,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Red Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9796,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9797,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9798,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9799,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Razor",
        "Right Leg": "Walking",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9800,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9801,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Yellow Skate",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9802,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Peace",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9803,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9804,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9805,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9806,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9807,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9808,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "Two Tone TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9809,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9810,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9811,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9812,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9813,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9814,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9815,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9816,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9817,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9818,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9819,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9820,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Pink Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9821,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9822,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9823,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9824,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Standing",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9825,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Rainbow Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9826,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9827,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9828,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9829,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9830,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Razor",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Black Skate",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9831,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Black TS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9832,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9833,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9834,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9835,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fist",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9836,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9837,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9838,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Wink",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9839,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9840,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Finger",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9841,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9842,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9843,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Cracked Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Wings",
        "Background": "Yellow"
    },
    {
        "ID": 9844,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Green"
    },
    {
        "ID": 9845,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9846,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9847,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Pitchfork",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9848,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Green Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9849,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9850,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Bomb",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9851,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9852,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9853,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Blue"
    },
    {
        "ID": 9854,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9855,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Ruby Slipper",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9856,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9857,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Kill Team",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Walking",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9858,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9859,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9860,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9861,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "So What",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9862,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9863,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Money Bag",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9864,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9865,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9866,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9867,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9868,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Standing",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9869,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Fist",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9870,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Light Purple"
    },
    {
        "ID": 9871,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Pink Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9872,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Green"
    },
    {
        "ID": 9873,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "Horns",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Leopord Print Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9874,
        "Character": "Cap",
        "Body Color": "Blacked Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9875,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "So What",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Candle",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9876,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9877,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Knife",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9878,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9879,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Bandage",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9880,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Black Beanie",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Smoking",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9881,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9882,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9883,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9884,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Rx Pattern Bomb",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9885,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Bandage with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9886,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9887,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9888,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9889,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9890,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Leopard Print One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9891,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9892,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running",
        "Celestial Attribute": "Devil Tail",
        "Background": "Dark Blue"
    },
    {
        "ID": 9893,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9894,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bomb",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9895,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9896,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Bandage",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9897,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9898,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9899,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9900,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9901,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Walking",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9902,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9903,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9904,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Black Skate",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9905,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Shifty",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "White Cuff Skate",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9906,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9907,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Sunglasses",
        "Mouth": "Lipstick",
        "Eyes": "Open",
        "Left Arm": "Knife",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9908,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9909,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Gold Crown",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9910,
        "Character": "Cap",
        "Body Color": "Rainbowed Out",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9911,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Weary",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Running",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9912,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Wink",
        "Left Arm": "White Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9913,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9914,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9915,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Candle",
        "Right Leg": "Running with Rainbow Gum",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9916,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Finger",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9917,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Crutch",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9918,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9919,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Rx Pattern Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9920,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Whatever",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9921,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Peace",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9922,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Lipstick with Cigarette",
        "Eyes": "Open",
        "Left Arm": "Candle",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9923,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Knife",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9924,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Black Wrist Band",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Power",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9925,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Hockey Mask",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cuff Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9926,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9927,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Black Skate Crossed",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9928,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Finger",
        "Right Leg": "Red Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9929,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Crutch",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "Knotted Laces with Rainbow Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9930,
        "Character": "Rex",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9931,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9932,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9933,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Corpse Paint",
        "Mouth": "",
        "Eyes": "",
        "Left Arm": "Leopard Print Knapsack",
        "Right Leg": "Walking with Rainbow Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9934,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Weary",
        "Left Arm": "Power",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9935,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Color Dots Money Bag",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9936,
        "Character": "Cap",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Pitchfork",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Peace",
        "Right Leg": "Black Skate",
        "Left Leg": "Hole in Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9937,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "Green Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Weary",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9938,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9939,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9940,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9941,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9942,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9943,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Green Cents Money Bag",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9944,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    },
    {
        "ID": 9945,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9946,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Pink Give Up Sign",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9947,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Power",
        "Right Leg": "White One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "Wings",
        "Background": "Desert"
    },
    {
        "ID": 9948,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9949,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Finger",
        "Right Leg": "Bomb",
        "Left Leg": "Pink Bomb",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9950,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Claw",
        "Headwear": "Pink Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9951,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9952,
        "Character": "Rex",
        "Body Color": "Rainbow",
        "Speech Bubble": "So What",
        "Right Arm": "Fan",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Closed Smile",
        "Eyes": "Happy",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Bandage with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9953,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "Robber Mask",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9954,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Peace",
        "Right Leg": "Red Skate",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9955,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running",
        "Left Leg": "Running with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9956,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "Red Punk Beret",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Shifty",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Yellow Skate",
        "Left Leg": "Black Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9957,
        "Character": "Syrin",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Fluf Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Running",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9958,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Red Skate",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9959,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9960,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Candle",
        "Right Leg": "White One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9961,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "White Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Weary",
        "Left Arm": "Smoking",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "Devil Tail",
        "Background": "Pink"
    },
    {
        "ID": 9962,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Running",
        "Left Leg": "Yellow Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9963,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Candle",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Dark Gray"
    },
    {
        "ID": 9964,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Pink Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Blow",
        "Eyes": "Shifty",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9965,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Far Out Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "Money Bag",
        "Right Leg": "Yellow Cast",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9966,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Peace",
        "Right Leg": "Rainbow Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Red Wine"
    },
    {
        "ID": 9967,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "One Sock",
        "Right Arm": "Claw",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Running",
        "Left Leg": "Hole in Blue Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9968,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "Green Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "Walking",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9969,
        "Character": "Rex",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Blue Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Peace",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9970,
        "Character": "Syrin",
        "Body Color": "Rainbow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Color Dots One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9971,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Bandaged Bunny Ears",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Candle",
        "Right Leg": "Knotted Laces with Pink Gum",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9972,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Crutch",
        "Headwear": "Green Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Bandage",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Green"
    },
    {
        "ID": 9973,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "Two Tone Pentagram Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Shaking Sunglasses",
        "Mouth": "Blow",
        "Eyes": "Open",
        "Left Arm": "Smoking",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9974,
        "Character": "Cap",
        "Body Color": "Red",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Solemn",
        "Left Arm": "White Knapsack",
        "Right Leg": "Green One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9975,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Red Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue Outta Here Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "Wings",
        "Background": "Dark Gray"
    },
    {
        "ID": 9976,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink Outta Here Sign",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9977,
        "Character": "Syrin",
        "Body Color": "Yellow",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Red RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval",
        "Eyes": "Solemn",
        "Left Arm": "Peace",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9978,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Umbrella",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Robber Mask",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Green Money Bag",
        "Right Leg": "Yellow One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9979,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Open Smile",
        "Eyes": "Open",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Red Cuff Skate",
        "Left Leg": "Running with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9980,
        "Character": "Syrin",
        "Body Color": "Blue",
        "Speech Bubble": "",
        "Right Arm": "Crutch",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Wink",
        "Left Arm": "Fist",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking",
        "Celestial Attribute": "Devil Tail",
        "Background": "Red Wine"
    },
    {
        "ID": 9981,
        "Character": "Rex",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Crutch",
        "Headwear": "Pink RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Frown",
        "Eyes": "Solemn",
        "Left Arm": "Smoking",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9982,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Pink See Ya Sign",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9983,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "Wood Crown",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Blue Give Up Sign",
        "Right Leg": "Green Cast",
        "Left Leg": "Ruby Slipper",
        "Celestial Attribute": "",
        "Background": "Dark Purple"
    },
    {
        "ID": 9984,
        "Character": "Cap",
        "Body Color": "Rainbow",
        "Speech Bubble": "Later Blader",
        "Right Arm": "Fan",
        "Headwear": "Red Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Desert"
    },
    {
        "ID": 9985,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "So What",
        "Right Arm": "Fist",
        "Headwear": "Black RxCPTS Tag Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "White Knapsack",
        "Right Leg": "White Cuff Skate",
        "Left Leg": "Red Cuff Crossed",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9986,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Color Dots Knapsack",
        "Right Leg": "Running",
        "Left Leg": "Hole in Pink Sock",
        "Celestial Attribute": "",
        "Background": "Orange"
    },
    {
        "ID": 9987,
        "Character": "Tabbi",
        "Body Color": "White",
        "Speech Bubble": "One Sock",
        "Right Arm": "Fan",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Shifty",
        "Left Arm": "Finger",
        "Right Leg": "Green One Sock",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9988,
        "Character": "Syrin",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Black Wrist Band",
        "Headwear": "Black Drug Receipts Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Blue Cast",
        "Left Leg": "Pigeon",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9989,
        "Character": "Syrin",
        "Body Color": "Gray",
        "Speech Bubble": "Whatever",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Open",
        "Left Arm": "Cents Money Bag",
        "Right Leg": "Running with Pink Gum",
        "Left Leg": "Running",
        "Celestial Attribute": "",
        "Background": "Light Blue"
    },
    {
        "ID": 9990,
        "Character": "Cap",
        "Body Color": "Pink",
        "Speech Bubble": "Whatever",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Eye Patch",
        "Mouth": "Blow",
        "Eyes": "Solemn",
        "Left Arm": "Fist",
        "Right Leg": "Bandage",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Dark Blue"
    },
    {
        "ID": 9991,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Yellow Lid",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Knife",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Dark Green"
    },
    {
        "ID": 9992,
        "Character": "Cap",
        "Body Color": "Blue",
        "Speech Bubble": "So What",
        "Right Arm": "Claw",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "Sunglasses",
        "Mouth": "Oval Frown",
        "Eyes": "Open",
        "Left Arm": "Power",
        "Right Leg": "Running",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9993,
        "Character": "Cap",
        "Body Color": "Redded Out",
        "Speech Bubble": "Broken Heart",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "Horns",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Happy",
        "Left Arm": "Power",
        "Right Leg": "Knotted Laces",
        "Left Leg": "Walking with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9994,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "",
        "Right Arm": "Peace",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Smile",
        "Eyes": "Happy",
        "Left Arm": "Blue See Ya Sign",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Light Gray"
    },
    {
        "ID": 9995,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "One Sock",
        "Right Arm": "Finger",
        "Headwear": "",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval Frown",
        "Eyes": "Shifty",
        "Left Arm": "Smoking",
        "Right Leg": "Walking with Pink Gum",
        "Left Leg": "Hole in Yellow Sock",
        "Celestial Attribute": "",
        "Background": "Red"
    },
    {
        "ID": 9996,
        "Character": "Rex",
        "Body Color": "Brown",
        "Speech Bubble": "",
        "Right Arm": "Spiked Bracelet",
        "Headwear": "Black Beanie",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Happy",
        "Left Arm": "Heavy Metal",
        "Right Leg": "Pink One Sock",
        "Left Leg": "Standing with Orange Bandaid",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9997,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "",
        "Right Arm": "Fan",
        "Headwear": "Two Tone Rx Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Open Smile",
        "Eyes": "Wink",
        "Left Arm": "Knife",
        "Right Leg": "Black Skate",
        "Left Leg": "Walking",
        "Celestial Attribute": "",
        "Background": "Yellow"
    },
    {
        "ID": 9998,
        "Character": "Cap",
        "Body Color": "Green",
        "Speech Bubble": "Not My Prob",
        "Right Arm": "Fist",
        "Headwear": "",
        "Floating Accessory": "Broken Halo",
        "Head Embellishment": "",
        "Face Accessory": "Flim Flam Sunglasses",
        "Mouth": "Oval Smile",
        "Eyes": "Solemn",
        "Left Arm": "Knife",
        "Right Leg": "White Cast",
        "Left Leg": "Standing with Pink Bandaid",
        "Celestial Attribute": "",
        "Background": "Pink"
    },
    {
        "ID": 9999,
        "Character": "Cap",
        "Body Color": "White",
        "Speech Bubble": "Whatever",
        "Right Arm": "Finger",
        "Headwear": "Two Tone RxCPTS Hat",
        "Floating Accessory": "",
        "Head Embellishment": "",
        "Face Accessory": "",
        "Mouth": "Oval",
        "Eyes": "Open",
        "Left Arm": "Fist",
        "Right Leg": "Bomb",
        "Left Leg": "Green Bomb",
        "Celestial Attribute": "",
        "Background": "Light Purple"
    }
]