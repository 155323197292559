import { useEffect, useRef, useState } from "react";
import styles from "./FrameCanvas.module.css";

import * as AssetAPI from "../utils/assetApi";
import Button from "./Button";

import canvasTxt from 'canvas-txt'
import { tokenData } from "../utils/data";
import { tokeIdToRank } from "../utils/rank";

const SnugDealers_white = require("../images/drugWar/Builder/Frames/Snug Dealers/SnugDealers_white.png");
const SnugDealers_gray = require("../images/drugWar/Builder/Frames/Snug Dealers/SnugDealers_gray.png");
const SetTrippers_white = require("../images/drugWar/Builder/Frames/Set Trippers/SetTrippers_white.png");
const SetTrippers_gray = require("../images/drugWar/Builder/Frames/Set Trippers/SetTrippers_gray.png");
const SetTrippers_blue = require("../images/drugWar/Builder/Frames/Set Trippers/SetTrippers_blue.png");
const BigPharma_white = require("../images/drugWar/Builder/Frames/Big Pharma/BigPharma_white.png");
const BigPharma_gray = require("../images/drugWar/Builder/Frames/Big Pharma/BigPharma_gray.png");
const BigPharma_blue = require("../images/drugWar/Builder/Frames/Big Pharma/BigPharma_blue.png");
const BigPharma_green = require("../images/drugWar/Builder/Frames/Big Pharma/BigPharma_green.png");
const Kingpin_white = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_white.png");
const Kingpin_gray = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_gray.png");
const Kingpin_blue = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_blue.png");
const Kingpin_green = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_green.png");
const Kingpin_gold = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_gold.png");


let timeoutId = null;
let startProductBarPos = -1;

export default function FrameBackCanvas({ nfts, myteamName, myteamLore, frameUrl, showGallery, downloaded, downloadBack }) {
    const canvasRef = useRef(null);
    const downloadButtonRef = useRef(null);
    const [teamName, setTeamName] = useState("MY TEAM NAME");
    const [frameImg, setFrameImg] = useState("");

    const getImageUrl = async (tokenId) => {
        // return AssetAPI.getImageUrl(tokenId);
        const addRandomToUrl = true;
        let url = AssetAPI.getImageUrl(tokenId, addRandomToUrl);
        let loc = document.location.origin;
        // return url;
        try {
            let response = await fetch(url, {
                method: "GET",
                headers: new Headers({
                    Origin: loc,
                }),
                mode: "cors",
            });
            let blob = response.blob();
            return blob;
        } catch (e) {
            console.error(e);
        }
    };

    const drawName = (name, teamLore) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(function () {
            addLastLayer(function () {
                if(name=="") name = " ";
                if(teamLore=="") teamLore = " ";
                const canvas = canvasRef.current;
                const context = canvas.getContext("2d");
                context.font = "5.6em octin-stencil";
                context.fillStyle = "#818386";
                // if(frameUrl.slice(-5)=='white'){
                //     context.fillStyle = "#818386";
                // }else{
                //     context.fillStyle = "#FFFFFF";
                // }
                context.textAlign = 'center';
                context.fillText(name, (canvas.width)/2, 210);

                // const context1 = canvas.getContext("2d");
                // context1.font = "3em open-sans";
                // if(frameUrl.slice(-5)=='white'){
                //     context1.fillStyle = "#818386";
                // }else{
                //     context1.fillStyle = "#FFFFFF";
                // }
                // context1.textAlign = 'left';
                // context1.fillText(teamLore, 120, 420);
                canvasTxt.fillStyle="#818386"
                canvasTxt.fontSize = 42
                canvasTxt.align = 'left'
                canvasTxt.justify = false
                canvasTxt.vAlign = 'top'
                canvasTxt.lineHeight = 60
                canvasTxt.fontWeight = 100
                canvasTxt.font = "Montserrat-Regular"
                canvasTxt.drawText(context, teamLore, 140, 252, 1296, 200)

                canvasTxt.fontSize = 40
                canvasTxt.font = "pressio-condensed"
                canvasTxt.fontWeight = 500
                canvasTxt.align = 'center'
                canvasTxt.drawText(context, "DRX ID", 120, 1242, 200, 100)
                canvasTxt.drawText(context, "RANK", 320, 1242, 200, 100)
                canvasTxt.drawText(context, "CHARACTER", 520, 1242, 300, 100)
                canvasTxt.drawText(context, "BODY COLOR", 820, 1242, 300, 100)
                canvasTxt.drawText(context, "BG COLOR", 1120, 1242, 300, 100)
                canvasTxt.font = "OpenSans_Condensed-Light"
                canvasTxt.fontWeight = 500
                canvasTxt.fontSize = 40
                if(nfts!=[]){
                    let l=0;
                    for(let i=0;i<nfts.length;i++){
                        if(nfts[i]!=""){
                            canvasTxt.drawText(context, nfts[i].toString(), 120, 1308+72*l, 200, 100);
                            canvasTxt.drawText(context, tokeIdToRank[nfts[i]].toString(), 320, 1308+72*l, 200, 100);
                            canvasTxt.drawText(context, tokenData[nfts[i]].Character.toString(), 520, 1308+72*l, 300, 100);
                            canvasTxt.drawText(context, tokenData[nfts[i]]["Body Color"].toString(), 820, 1308+72*l, 300, 100);
                            canvasTxt.drawText(context, tokenData[nfts[i]].Background.toString(), 1120, 1308+72*l, 300, 100);
                            l++;
                        }
                    }
                }

                // canvasTxt.drawText(context, "8663", 120, 1122, 200, 100)
                // canvasTxt.drawText(context, "3486", 320, 1122, 200, 100)
                // canvasTxt.drawText(context, "TABBI", 520, 1122, 300, 100)
                // canvasTxt.drawText(context, "WHITE", 820, 1122, 300, 100)
                // canvasTxt.drawText(context, "LIGHT PURPLE", 1120, 1122, 300, 100)

                // canvasTxt.drawText(context, "8663", 120, 1222, 200, 100)
                // canvasTxt.drawText(context, "3486", 320, 1222, 200, 100)
                // canvasTxt.drawText(context, "TABBI", 520, 1222, 300, 100)
                // canvasTxt.drawText(context, "WHITE", 820, 1222, 300, 100)
                // canvasTxt.drawText(context, "LIGHT PURPLE", 1120, 1222, 300, 100)

                // canvasTxt.drawText(context, "8663", 120, 1322, 200, 100)
                // canvasTxt.drawText(context, "3486", 320, 1322, 200, 100)
                // canvasTxt.drawText(context, "TABBI", 520, 1322, 300, 100)
                // canvasTxt.drawText(context, "WHITE", 820, 1322, 300, 100)
                // canvasTxt.drawText(context, "LIGHT PURPLE", 1120, 1322, 300, 100)

                // canvasTxt.drawText(context, "8663", 120, 1422, 200, 100)
                // canvasTxt.drawText(context, "3486", 320, 1422, 200, 100)
                // canvasTxt.drawText(context, "TABBI", 520, 1422, 300, 100)
                // canvasTxt.drawText(context, "WHITE", 820, 1422, 300, 100)
                // canvasTxt.drawText(context, "LIGHT PURPLE", 1120, 1422, 300, 100)

                context.beginPath();
                context.moveTo(140, 1228);
                context.lineTo(1436, 1228);
                context.stroke();
                
                context.beginPath();
                context.moveTo(140, 1300);
                context.lineTo(1436, 1300);
                context.stroke();

                context.beginPath();
                context.moveTo(140, 1370);
                context.lineTo(1436, 1370);
                context.stroke();

                context.beginPath();
                context.moveTo(140, 1442);
                context.lineTo(1436, 1442);
                context.stroke();

                context.beginPath();
                context.moveTo(140, 1514);
                context.lineTo(1436, 1514);
                context.stroke();

                // context.beginPath();
                // context.moveTo(140, 1580);
                // context.lineTo(1436, 1580);
                // context.stroke();
            });
        }, 250);
    };

    const addLastLayer = (callback) => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        const image = new Image();
        image.addEventListener("load", function () {
            context.drawImage(this, 0, 0);
            if (callback) {
                callback();
            }
        });
        image.src = require("../images/drugWar/Card Back/Card Back.png");
        // if(frameUrl=="../images/drugWar/Builder/Frames/Kingpin/Kingpin_white.png"){
        //     image.src = SnugDealers_white_img;
        // }else{
        //     image.src = SnugDealers_gray_img;
        // }
    };
    // useEffect(() => {
    //     setFrameImg(frameUrl);
    //     drawName(myteamName, myteamLore);
    // }, [frameUrl]);

    // useEffect(() => {
    //     setTeamName(myteamName);
    //     const canvas = canvasRef.current;
    //     const context = canvas.getContext("2d");
    //     context.clearRect(0, 0, canvas.width, canvas.height);
    //     // addLastLayer();
    //     drawName(teamName);
    // }, []);

    useEffect(() => {
        setTeamName(myteamName);
        drawName(myteamName, myteamLore, nfts);
    }, [myteamName, myteamLore, nfts]);

    // useEffect(() => {
    //     const canvas = canvasRef.current;
    //     const context = canvas.getContext("2d");

    //     // let emptyCount = 0;
    //     // for (let i = 0; i < nfts.length; i++) {
    //     //     let x = 100;
    //     //     let y = 100;
    //     //     switch (i) {
    //     //         case 1:
    //     //             x = 798;
    //     //             break;
    //     //         case 2:
    //     //             y = 800;
    //     //             break;
    //     //         case 3:
    //     //             x = 798;
    //     //             y = 800;
    //     //             break;
    //     //     }

    //     //     if (nfts[i] === "") {
    //     //         emptyCount++;
    //     //     }
    //     //     setTimeout(async () => {
    //     //         if (nfts[i] === "") {
    //     //             context.clearRect(x, y, 676, 676);
    //     //         } else {
    //     //             const image = new Image();
    //     //             image.crossOrigin = "anonymous";
    //     //             image.addEventListener("load", function () {
    //     //                 context.drawImage(this, x, y, 676, 676);
    //     //                 drawName(teamName);
    //     //             });
    //     //             const blob = await getImageUrl(nfts[i]);
    //     //             image.src = URL.createObjectURL(blob);
    //     //         }
    //     //     }, 750);
    //     // }
    // }, [nfts]);

    useEffect(() => {
        if(downloaded>=1){
            const canvas = canvasRef.current;
            setTimeout(()=>{
                downloadBack(canvas.toDataURL());
            }, 100);
            
            // const link = document.createElement('a');
            // link.download = "drugreceipts_back.png";
            // link.href = canvas.toDataURL();
            // link.click();
        }
    }, [downloaded]);

    const downloadImage = () => {
        const canvas = canvasRef.current;
        const button = downloadButtonRef.current;
        button.setAttribute("download", teamName + "_back.png");
        button.setAttribute("href", canvas.toDataURL());
    };

    const containerRef = useRef(null);
    useEffect(() => {
        function findPosY(obj) {
            var curtop = 0;
            if (typeof obj.offsetParent != "undefined" && obj.offsetParent) {
                while (obj.offsetParent) {
                    curtop += obj.offsetTop;
                    obj = obj.offsetParent;
                }
                curtop += obj.offsetTop;
            } else if (obj.y) curtop += obj.y;
            return curtop;
        }

        const c = containerRef.current;
        const ctr = c.parentNode.parentNode.parentNode;
        ctr.onscroll = () => {
            var w =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
            if (w < 640) {
                return;
            }

            var bar = containerRef.current;
            if (startProductBarPos < 0) startProductBarPos = findPosY(bar);

            if (ctr.scrollTop > startProductBarPos) {
                bar.style.position = "fixed";
                bar.style.top = 0;
            } else {
                bar.style.position = "relative";
            }
        };
    }, []);

    return (
        <div ref={containerRef} className="">
            <div className="">
                <canvas
                    ref={canvasRef}
                    width="1576"
                    height="1895"
                    className={styles.canvas}
                />
            </div>
            {/* <div className="flex gap-2 items-center my-4 max-w-[400px]">
                <div className="w-1/2">
                    <input
                        type="text"
                        className={styles.input}
                        value={teamName}
                        onChange={({ target: { value } }) => {
                            if (value.length <= 14) {
                                setTeamName(value);
                            }
                        }}
                    />
                </div>
                <div className="w-1/2"></div>
            </div> */}
            {/* <div className="mt-8">
                <Button  clickFunction={downloadImage}>
                    <a
                        className={styles.downloadButton}
                        target="_blank"
                        ref={downloadButtonRef}
                        rel="noreferrer"
                    >
                        DOWNLOAD PNG
                    </a>
                </Button>
            </div> */}
        </div>
    );
}
