import { Link } from "react-router-dom";
import { ReactComponent as Close } from "../images/X.svg";
import logo from "../images/drugWar/Hero/Logo_White.png";

export default function Sidebar({ toggleSidebar, isSidebarOpen, menuItems, socialIcons }) {

    return (
        <div className={`fixed flex flex-wrap justify-center items-center
        top-0 left-0 bottom-0 right-0 z-10 
        bg-opacity-60 bg-black backdrop-blur-sm w-screen h-full
        transition ease-in-out duration-300 `}
        >

            <div className="flex flex-col fixed justify-start items-start p-5 w-[60%] h-[100%]
             bg-[#000000] right-0">

                <div onClick={toggleSidebar}
                    className="flex absolute right-0 top-0 p-2 xs:p-3 m-5 fill-black
                    justify-center items-center h-8 w-8 xs:h-10 xs:w-10 rounded-full
                    hover:cursor-pointer transform hover:scale-90 bg-[#00000] border-2 border-white-900">
                    <Close className="" />
                </div>

                {/* <Link to='/drugwars' className='mb-12'> */}
                    <img src={logo} alt="logo" className="w-[120px] xs:w-[160px] hover:cursor-pointer mb-12" />
                {/* </Link> */}

                <div className="my-12 ">{menuItems}</div>
                <div className="my-12 ">{socialIcons}</div>

                <div className="text-white h-full justify-center text-center items-center">
                    <h1 className="font-pressio-condensed text-5xl"> </h1>
                </div>

            </div>
        </div>
    );
};


// return (
//     <div className="">
//         <div
//             className={`top-0 right-0 w-[35vw] bg-white backdrop-brightness-0 p-10 pl-20 fixed h-full z-40
//         ease-in-out duration-300
//         ${"translate-x-0 "}`}
//         >
//             <h3 className="mt-20 text-4xl font-semibold ">
//                 I am a sidebar
//             </h3>
//         </div>
//     </div>
// );