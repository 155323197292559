// import _ from 'lodash';

const getHeaders = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
  };
};


const extractBody = (res) => {
  if (res.ok) {
    return res.json();
  } else {
    return res.text().then((text) => {
      console.log('Error: ', text);
      let errMessage;
      try {
        let err = JSON.parse(text);
        errMessage = err.message;
      } catch (error) { }
      throw new Error(errMessage || text);
    });
  }
};

const extractBody1 = (res) => {
  return res;
  // if (res.ok) {
  //   return res;
  // } else {
  //   return res.text().then((text) => {
  //     console.log('Error: ', text);
  //     let errMessage;
  //     try {
  //       let err = JSON.parse(text);
  //       errMessage = err.message;
  //     } catch (error) { }
  //     throw new Error(errMessage || text);
  //   });
  // }
};

// const BASE_URL = `http://localhost:${3001}`;
const BASE_URL = `https://api.drugreceipts.com`;

const BASE_BACKEND_URL = `https://50.19.120.103:8443`;

// const BASE_BACKEND_URL = `http://localhost:8000`;

export const saveContact = (data) => {
  let url = BASE_BACKEND_URL + `/api/contact`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    ...getHeaders(),
  }).then((res) => {
    return extractBody1(res);
  });
}

export const deleteContact = (walletAddress) => {
  if (walletAddress != "") {
    let url = BASE_BACKEND_URL + `/api/contact/${walletAddress}`;
    return fetch(url, {
      method: "DELETE",
      ...getHeaders(),
    }).then((res) => {
      return extractBody1(res);
    });
  } else {
    return "";
  }
}
export const updateContact = (data, walletAddress) => {
  if (walletAddress != "") {
    let url = BASE_BACKEND_URL + `/api/contact/${walletAddress}`;
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      ...getHeaders(),
    }).then((res) => {
      return extractBody1(res);
    });
  } else {
    return "";
  }
}

export const getContactByAddress = (walletAddress) => {
  if (walletAddress != "") {
    let url = BASE_BACKEND_URL + `/api/contact/${walletAddress}`;
    return fetch(url, {
      method: 'GET',
      ...getHeaders(),
    }).then((res) => {
      return extractBody1(res);
    });
  }
  return "";
};

export const saveToGallery = (data) => {
  let url = BASE_BACKEND_URL + `/api/gallery`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    ...getHeaders(),
  }).then((res) => {
    return extractBody1(res);
  });
}

export const deleteGallery = (id) => {
  if (id != "") {
    let url = BASE_BACKEND_URL + `/api/gallery/${id}`;
    return fetch(url, {
      method: "DELETE",
      ...getHeaders(),
    }).then((res) => {
      return extractBody1(res);
    });
  } else {
    return "";
  }
}
export const updateGallery = (data, id) => {
  if (id != "") {
    let url = BASE_BACKEND_URL + `/api/gallery/${id}`;
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      ...getHeaders(),
    }).then((res) => {
      return extractBody1(res);
    });
  } else {
    return "";
  }
}

export const submitGallery = (id) => {
  if (id != "") {
    let url = BASE_BACKEND_URL + `/api/gallery/submit`;
    return fetch(url, {
      method: "POST",
      ...getHeaders(),
      body: JSON.stringify({ id: id }),
    }).then((res) => {
      return extractBody1(res);
    });
  } else {
    return "";
  }
}

export const getGalleriesByAddress = (walletAddress) => {
  if (walletAddress != "") {
    let url = BASE_BACKEND_URL + `/api/gallery/${walletAddress}`;
    return fetch(url, {
      method: 'GET',
      ...getHeaders(),
    }).then((res) => {
      return extractBody1(res);
    });
  }
  return "";
}

export const getGallery = () => {
  // if (walletAddress != "") {
    let url = BASE_BACKEND_URL + `/api/gallery`;
    return fetch(url, {
      method: 'GET',
      ...getHeaders(),
    }).then((res) => {
      return extractBody1(res);
    });
  // }
  // return "";
}

export const getBattleBystageId = (stageId) => {
  // if (walletAddress != "") {
  let url = BASE_BACKEND_URL + `/api/battle?stage_id=${stageId}`;
  return fetch(url, {
    method: 'GET',
    ...getHeaders(),
  }).then((res) => {
    return extractBody1(res);
  });
}

export const getVotebyBattleId = (battleId) => {
  // if (walletAddress != "") {
  let url = BASE_BACKEND_URL + `/api/vote?battle_id=${battleId}`;
  return fetch(url, {
    method: 'GET',
    ...getHeaders(),
  }).then((res) => {
    return extractBody1(res);
  });
}

export const saveVote = (data) => {
  let url = BASE_BACKEND_URL + `/api/vote`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    ...getHeaders(),
  }).then((res) => {
    return extractBody1(res);
  });
} 

export const getStageById = (stageId) => {
  // if (walletAddress != "") {
  let url = BASE_BACKEND_URL + `/api/stage/?id=${stageId}`;
  return fetch(url, {
    method: 'GET',
    ...getHeaders(),
  }).then((res) => {
    return extractBody1(res);
  });
}
// return "";

export const getImageUrl = (tokenId, type = 0, rand = false) => {
  rand = true;
  // const url = `https://drugreceipts-5t931f461a.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_2160.png`;
  // const url = `https://drugreceipts-compressed-5t931f461b.s3.ap-southeast-1.amazonaws.com/render_${tokenId}/image_${tokenId}_432.png`?${Math.random()}`;
  let url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_432.png${rand ? '?' + Math.random() : ''}`;
  switch (type) {
    case 1: {
      url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/transparent_image_${tokenId}_2160.png${rand ? '?' + Math.random() : ''}`;
      break;
    }
    case 2: {
      url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_2160.png${rand ? '?' + Math.random() : ''}`;
      break;
    }
    case 3: {
      url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_3840.png${rand ? '?' + Math.random() : ''}`;
      break;
    }
    case 4: {
      url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_landscape.png${rand ? '?' + Math.random() : ''}`;
      break;
    }
    case 5: {
      url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_portrait.png${rand ? '?' + Math.random() : ''}`;
      break;
    }
    case 6: {
      url = `https://drugreceipts.s3.eu-west-1.amazonaws.com/Lab/3840_Extended_No_Glow`;
      break;
    }
    case 7: {
      url = `https://drugreceipts.s3.eu-west-1.amazonaws.com/transparent_token_image_with_glow/transparent_image_${tokenId}_2160.png_with_glow.png`;
      break;
    }
    default: {
      url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_432.png${rand ? '?' + Math.random() : ''}`;
      break;
    }
  }
  // const url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_432.png${rand ? '?' + Math.random() : ''}`;
  return url;
}

export const retrieveTokensForWallet = (walletAddress, full) => {
  let url = `https://50.19.120.103:8443/api/wallet/${walletAddress}`;
  // let url = `${BASE_URL}/api/wallet/${walletAddress}`;

  if (full) { url += '/full'; }
  return fetch(url, {
    method: 'GET',
    ...getHeaders(),
  }).then((res) => {
    return extractBody(res);
  });
};

export const getTokenInfo = (tokenId) => {
  let url = `${BASE_URL}/api/token/${tokenId}`;
  return fetch(url, {
    method: 'GET',
    ...getHeaders(),
  }).then((res) => {
    return extractBody(res);
  });
};
